import { Customer } from 'yf-commerce-model-ui-lib';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { environment } from './../../environments/environment';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnDestroy {

	public url: any;
	env = environment;
	loggedUser: Customer;
	isbrowser: boolean;
	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private router: Router, private sharedDataService: SharedDataService) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.url = event.url;
			}
		});

		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser as unknown as Customer; });
		this.isbrowser = isPlatformBrowser(this.platformId)
	}
	ngOnInit(): void {
		// Change color for this layout
		if (isPlatformBrowser(this.platformId)) {
			document.documentElement.style.setProperty('--theme-deafult', environment.themecolor);
		}


	}
	ngOnDestroy(): void {
		// Remove Color
		if (isPlatformBrowser(this.platformId)) {
			document.documentElement.style.removeProperty('--theme-deafult');
		}
	}

	get landing() {
		return environment.b2b && !this.loggedUser;
	}

}
