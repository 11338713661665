import { environment } from 'src/environments/environment';
import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ShopProductService } from 'yf-commerce-ui-lib';
import { Cart } from 'yf-commerce-model-ui-lib';
import { ShopCartService } from 'yf-commerce-ui-lib';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CartItem, Customer, Product } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	@Input()
	landing: boolean;

	searching: boolean;
	public products: Product[] = [];
	@ViewChild('searchinput', {}) searchinput: ElementRef;

	public languages = environment.languages;


	public currencies = [{
		name: 'Euro',
		currency: 'EUR',
		price: 0.90 // price of euro
	}, {
		name: 'Rupees',
		currency: 'INR',
		price: 70.93 // price of inr
	}, {
		name: 'Pound',
		currency: 'GBP',
		price: 0.78 // price of euro
	}, {
		name: 'Dollar',
		currency: 'USD',
		price: 1 // price of usd
	}];
	selectedlang: any;
	cart: Cart;
	loggedUser: Customer;
	searchterm: string;
	ismobile: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private translate: TranslateService,
		public productService: ShopProductService,
		private cartService: ShopCartService,
		private sharedDataService: SharedDataService,
		private router: Router,
		private deviceService: DeviceDetectorService) {
		this.productService.cartItems.subscribe(response => this.products = response);

		this.cartService.cart.subscribe((data: Cart) => {
			this.cart = data;
		});

		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser as unknown as Customer; });
		this.ismobile = this.deviceService.isMobile() || this.deviceService.isTablet();
	}

	ngOnInit(): void {
		this.selectedlang = environment.defaultlang;
		this.sharedDataService.setLanguage(this.selectedlang);
		this.translate.use(this.selectedlang);
	}

	changeLanguage(code) {
		if (isPlatformBrowser(this.platformId)) {
			this.translate.use(code);
			this.selectedlang = code;
			this.sharedDataService.setLanguage(this.selectedlang);
		}
	}



	removeItem(product: CartItem) {
		this.cartService.remove(product);
	}

	changeCurrency(currency: any) {
		this.productService.Currency = currency;
	}


	opensearch() {
		this.searching = true;

		setTimeout(() => { // this will make the execution after the above boolean has changed
			if (this.searchinput) {
				this.searchinput.nativeElement.focus();
			}
		}, 0);
	}


	search() {
		this.router.navigate(
			['/shop/search'],
			{
				queryParams: { s: this.searchterm },
				queryParamsHandling: 'merge',
			});
		this.searching = false;
	}

	get checkoutURL() {
		return environment.checkoutURL;
	}

}
