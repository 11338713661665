import { Component, OnInit, Input } from '@angular/core';
import { Slider } from 'yf-commerce-model-ui-lib';
import { HomeSlider } from '../../../shared/data/slider';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

	constructor() { }

	@Input() sliders: any[];
	@Input() class: string;
	@Input() textClass: string;
	@Input() category: string;
	@Input() buttonText: string = 'shopnow';
	@Input() buttonClass: string;
	@Input() landing: boolean;

	@Input() slider: Slider;

	public HomeSliderConfig: any = HomeSlider;

	ngOnInit(): void {



	}

}
