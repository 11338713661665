import { isPlatformBrowser } from '@angular/common';
import { environment } from './../../../environments/environment.b2b';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { Md5 } from 'ts-md5';
import { CustomerService } from 'yf-commerce-ui-lib';
import { ResetToken } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	env = environment;
	loading: boolean;
	token: string;
	message: string;
	form: boolean;
	password: string;
	rpassword: string;
	userid: number;

	done: boolean;
	isbrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private route: ActivatedRoute,
		private customerService: CustomerService,
		private sharedDataService: SharedDataService) {
		this.isbrowser = isPlatformBrowser(platformId);
	}

	ngOnInit() {
		this.loading = true;
		this.route.params.subscribe(params => {
			this.token = params['token'];
			if (!this.token) {
				this.message = 'Token non valido.';
			} else {
				this.validateToken();
			}
		});


	}

	validateToken() {
		this.customerService.validateToken(this.token).subscribe((data: ResetToken) => {
			this.userid = data.eid;
			this.loading = false;
			this.message = '';
			this.form = true;
		}, error => {
			console.log('error', error);
			this.form = false;
			this.loading = false;
			this.message = 'Si è verificato un errore durante l\'attivazione. Link non valido o scaduto.';
		});
	}

	submit() {
		const md5pass = Md5.hashStr(this.password) as string;
		this.customerService.changePassword(this.userid, md5pass, this.token).subscribe(data => {
			this.loading = false;
			this.done = true;
			this.message = '';
		}, error => {
			console.log('error', error);

			this.loading = false;
			this.message = 'Si è verificato un errore durante l\'attivazione. Link non valido o scaduto.';
		});

	}
}
