<owl-carousel-o
	[options]="CollectionSliderConfig"
	class="category-m no-arrow">
	<ng-container *ngFor="let collection of categories">
		<ng-template carouselSlide>
			<div>
				<div
					class="category-wrapper"
					[ngClass]="class">
					<div>
						<img
							asyncimg
							[opaque]="collection.cover"
							size="md"
							class="img-fluid w-auto"
							alt="collection-banner">
						<h4>{{collection.title}}</h4>
						<ul
							class="category-link"
							[innerHTML]="collection.shortdesc"></ul>
						<a
							[routerLink]="['/shop/collection/', collection.slug]"
							class="btn btn-outline">
							view more
						</a>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>
