import { Component, OnInit } from '@angular/core';
import { CatalogService } from 'yf-commerce-ui-lib';
import { ProductBoxBaseComponent } from '../product-box-base/product-box-base.component';
import { ShopCartService, ShopProductService } from 'yf-commerce-ui-lib';
import { Router } from '@angular/router';

@Component({
	selector: 'app-product-box-vertical',
	templateUrl: './product-box-vertical.component.html',
	styleUrls: ['./product-box-vertical.component.scss']
})
export class ProductBoxVerticalComponent extends ProductBoxBaseComponent implements OnInit {


	constructor(catalogService: CatalogService,
		productService: ShopProductService,
		cartService: ShopCartService,
		router: Router) {
		super(catalogService, productService, cartService, router);
	}

	ngOnInit(): void {
	}



}
