<div class="img-wrapper">
	<div class="lable-block">
		<span
			class="lable3"
			*ngIf="product.ispromo>
			new
	</div>
	<div class="front">
		<a [routerLink]="['/shop/product/left/sidebar/', product.slug]">
			<img
				[defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
				[lazyLoad]="ImageSrc ? ImageSrc : product.images[0]?.src"
				class="img-fluid lazy-loading"
				alt="{{ product?.images[0]?.alt }}">
		</a>
	</div>
	<div
		class="back"
		*ngIf="onHowerChangeImage && product.images.length > 1">
		<a [routerLink]="['/shop/product/left/sidebar/', product.slug]">
			<img
				[src]="ImageSrc ? ImageSrc : product.images[1].src"
				class="img-fluid lazy-loading"
				alt>
		</a>
	</div>
	<ul
		class="product-thumb-list"
		*ngIf="thumbnail">
		<li
			class="grid_thumb_img"
			[class.active]="ImageSrc == image.src"
			*ngFor="let image of product.images">
			<a
				href="javascript:void(0)"
				(mouseover)="ChangeVariantsImage(image.src)">
				<img [lazyLoad]="image.src">
			</a>
		</li>
	</ul>
	<div class="cart-detail">
		<a
			href="javascript:void(0)"
			title="Add to Wishlist"
			(click)="addToWishlist(product)">
			<i
				class="ti-heart"
				aria-hidden="true"></i>
		</a>
		<a
			href="javascript:void(0)"
			title="Quick View"
			(click)="QuickView.openModal()">
			<i
				class="ti-search"
				aria-hidden="true"></i>
		</a>
		<a
			href="javascript:void(0)"
			title="Compare"
			(click)="addToCompare(product)">
			<i
				class="ti-reload"
				aria-hidden="true"></i>
		</a>
	</div>
</div>
<div class="product-info">
	<div>

		<a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
			<h6>{{ product?.title | titlecase }}</h6>
		</a>
		<h4>
			{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
			<del *ngIf="product?.discount">
				<span class="money">
					{{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}
				</span>
			</del>
		</h4>
		<ul
			class="color-variant"
			*ngIf="Color(product?.variants).length">
			<li
				[class]="color"
				*ngFor="let color of Color(product?.variants)"
				[ngStyle]="{'background-color': color}"
				(click)="ChangeVariants(color, product)">
			</li>
		</ul>
		<div class="add-btn">
			<a
				href="javascript:void(0)"
				class="btn btn-outline"
				title="Add to cart"
				(click)="CartModal.openModal(product)"
				*ngIf="cartModal">
				<i class="ti-shopping-cart"></i>
				Add to cart
			</a>
			<a
				href="javascript:void(0)"
				class="btn btn-outline"
				title="Add to cart"
				(click)="addToCart(product)"
				*ngIf="!cartModal">
				<i class="ti-shopping-cart"></i>
				Add to cart
			</a>
		</div>
	</div>
</div>

<app-quick-view
	#quickView
	[product]="product"
	[currency]="currency"></app-quick-view>
<app-cart-modal
	#cartModal
	[product]="product"
	[currency]="currency"
	*ngIf="cartModal"></app-cart-modal>
