<section
	class="login-page section-b-space"
	*ngIf="isbrowser">
	<div class="card-body">

		<div
			class="loading"
			*ngIf="!loggedUser">
			<i class="fa fa-circle-o-notch fa-spin fa-2x"></i>
		</div>

		<form
			*ngIf="loggedUser"
			class="theme-form"
			#pwdForm="ngForm"
			(ngSubmit)="pwdForm.valid && changepwd()">

			<ng-template [ngIf]="!updated">
				<h3 class="box-title m-b-20">
					{{'change-password-page.h3' | translate}}
				</h3>
				<div class="form-group">
					<div class="col-xs-12">
						<input
							name="password"
							class="form-control"
							type="password"
							required
							[(ngModel)]="loggedUser.plainpassword"
							placeholder="{{'labels.password' | translate}}">
					</div>
				</div>
				<div class="form-group">
					<div class="col-xs-12">
						<input
							name="confirmpassword"
							class="form-control"
							type="password"
							required
							[(ngModel)]="confirmpassword"
							placeholder="{{'labels.repeat-password' | translate}}">
					</div>
				</div>

				<div *ngIf="password != confirmpassword">
					<h5 class="text-danger">{{"password-mismatch" | translate}}</h5>
				</div>

				<button
					type="submit"
					class="btn btn-solid float-right"
					[disabled]="pwdForm.invalid || password != confirmpassword">
					{{'buttons.save' | translate}}
				</button>
			</ng-template>

			<ng-template [ngIf]="updated">
				<div class="col-sm-6 text-center">
					<img
						src="assets/images/icon-success.jpg"
						class="img-fluid mb-4">
					<h4>
						<strong>{{'Password Updated' | translate}}!</strong>
					</h4>
					<h5>
						{{'Your password has been changed successfully' | translate}}.
						<a
							class="link"
							(click)="updated = false">
							{{'Change again' | translate}}
						</a>
					</h5>

				</div>
			</ng-template>

		</form>

	</div>
</section>
