<div class="main-navbar">
	<div id="mainnav">
		<div
			class="toggle-nav"
			(click)="mainMenuToggle()">
			<i class="fa fa-bars sidebar-bar"></i>
		</div>
		<ul
			class="nav-menu"
			[class.opennav]="navServices?.mainMenuToggle">

			<li class="back-btn">
				<div
					class="mobile-back text-right"
					(click)="mainMenuToggle()">
					<span>{{'buttons.close' | translate}}</span>
					<i
						class="fa fa-angle-right pl-2"
						aria-hidden="true"></i>
				</div>
			</li>

			<li
				*ngFor="let menuItem of menuItems"
				[class.mega-menu]="menuItem.megaMenu">
				<!-- Sub -->
				<a
					href="javascript:void(0)"
					class="nav-link"
					*ngIf="menuItem.type === 'sub'"
					(click)="toggletNavActive(menuItem)">
					{{ menuItem.title | translate }}
					<div
						class="lable-nav"
						*ngIf="menuItem.badge">
						{{ menuItem.badgeText | translate }}
					</div>
					<span
						class="sub-arrow sub1"
						*ngIf="menuItem.children?.length"></span>
				</a>
				<!-- Link -->
				<a
					routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: true}"
					class="nav-link hide-desktop"
					*ngIf="menuItem.type === 'link'">
					<span (click)="navigateTo(menuItem.path)">{{ menuItem.title | translate }}</span>
					<div
						class="lable-nav"
						*ngIf="menuItem.badge">
						{{ menuItem.badgeText | translate }}
					</div>
					<span
						(click)="toggletNavActive(menuItem)"
						class="sub-arrow sub2"
						*ngIf="menuItem.children?.length"></span>
				</a>
				<a
					[routerLink]="menuItem.path"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: true}"
					class="nav-link hide-mobile"
					*ngIf="menuItem.type === 'link'">
					{{ menuItem.title | translate }}
					<div
						class="lable-nav"
						*ngIf="menuItem.badge">
						{{ menuItem.badgeText | translate }}
					</div>
					<span
						class="sub-arrow sub2"
						*ngIf="menuItem.children?.length"></span>
				</a>
				<!-- External Link -->
				<a
					href="{{ menuItem.path }}"
					class="nav-link"
					*ngIf="menuItem.type === 'extLink'"
					[target]="menuItem.target ? menuItem.target : ''">
					{{ menuItem.title | translate }}
					<div
						class="lable-nav"
						*ngIf="menuItem.badge">
						{{ menuItem.badgeText | translate }}
					</div>
					<span
						class="sub-arrow sub3"
						*ngIf="menuItem.children?.length"></span>
				</a>
				<!-- External Tab Link -->
				<a
					href="{{ menuItem.path }}"
					class="nav-link"
					*ngIf="menuItem.type === 'extTabLink'">
					{{ menuItem.title | translate }}
					<div
						class="lable-nav"
						*ngIf="menuItem.badge">
						{{ menuItem.badgeText | translate }}
					</div>
					<span
						class="sub-arrow sub4"
						*ngIf="menuItem.children?.length"></span>
				</a>

				<!-- 2nd Level Menu -->
				<ul
					class="nav-submenu"
					[class.opensubmenu]="menuItem.active"
					*ngIf="menuItem.children?.length">
					<li *ngFor="let childrenItem of menuItem.children">
						<!-- Sub -->
						<a
							href="javascript:void(0)"
							*ngIf="childrenItem.type === 'sub'"
							(click)="toggletNavActive(childrenItem)">
							{{ childrenItem.title | translate }}
							<span
								class="new-tag"
								*ngIf="childrenItem.badge">
								{{ childrenItem.badgeText | translate }}
							</span>
							<span
								class="sub-arrow sub5"
								*ngIf="childrenItem.children?.length"></span>
						</a>
						<!-- Link -->
						<a
							[routerLink]="childrenItem.path"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{exact: true}"
							*ngIf="childrenItem.type === 'link'"
							class="hide-mobile">
							{{ childrenItem.title | translate }}
							<span
								class="new-tag"
								*ngIf="childrenItem.badge">
								{{ childrenItem.badgeText | translate }}
							</span>
							<span
								class="sub-arrow sub6"
								*ngIf="childrenItem.children?.length"></span>
						</a>
						<a
							class="hide-desktop"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{exact: true}"
							*ngIf="childrenItem.type === 'link'">
							<span (click)="navigateTo(childrenItem.path)">{{ childrenItem.title | translate }}</span>
							<span
								class="new-tag"
								*ngIf="childrenItem.badge">
								{{ childrenItem.badgeText | translate }}
							</span>
							<span
								(click)="toggletNavActive(childrenItem)"
								class="sub-arrow sub6"
								*ngIf="childrenItem.children?.length"></span>
						</a>
						<!-- External Link -->
						<a
							href="{{ childrenItem.path }}"
							*ngIf="childrenItem.type === 'extLink'"
							[target]="menuItem.target ? menuItem.target : ''">
							{{ childrenItem.title | translate }}
							<span
								class="new-tag"
								*ngIf="childrenItem.badge">
								{{ childrenItem.badgeText | translate }}
							</span>
							<span
								class="sub-arrow sub7"
								*ngIf="childrenItem.children?.length"></span>
						</a>
						<!-- External Tab Link -->
						<a
							href="{{ childrenItem.path }}"
							target="_blank"
							*ngIf="childrenItem.type === 'extTabLink'">
							{{ childrenItem.title | translate }}
							<span
								class="new-tag"
								*ngIf="childrenItem.badge">
								{{ childrenItem.badgeText | translate }}
							</span>
							<span
								class="sub-arrow sub8"
								*ngIf="childrenItem.children?.length"></span>
						</a>

						<!-- 3rd Level Menu -->
						<ul
							*ngIf="childrenItem.children?.length"
							[class.opensubchild]="childrenItem.active"
							class="nav-sub-childmenu">
							<li *ngFor="let childrenSubItem of childrenItem.children">
								<!-- Link -->
								<a
									[routerLink]="childrenSubItem.path"
									routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}"
									*ngIf="childrenSubItem.type === 'link'">
									{{ childrenSubItem.title | translate }}
									<span
										class="new-tag"
										*ngIf="childrenSubItem.badge">
										{{ childrenSubItem.badgeText | translate }}
									</span>
								</a>
								<!-- External Link -->
								<a
									href="{{ childrenSubItem.path }}"
									*ngIf="childrenSubItem.type === 'extLink'"
									[target]="menuItem.target ? menuItem.target : ''">
									{{ childrenSubItem.title | translate }}
									<span
										class="new-tag"
										*ngIf="childrenSubItem.badge">
										{{ childrenSubItem.badgeText | translate }}
									</span>
								</a>
								<!-- External Tab Link -->
								<a
									href="{{ childrenSubItem.path }}"
									target="_blank"
									*ngIf="childrenSubItem.type === 'extTabLink'">
									{{ childrenSubItem.title | translate }}
									<span
										class="new-tag"
										*ngIf="childrenSubItem.badge">
										{{ childrenSubItem.badgeText | translate }}
									</span>
								</a>
							</li>
						</ul>
					</li>
				</ul>

				<div
					class="mega-menu-container"
					*ngIf="menuItem.megaMenu && menuItem.children">
					<div class="container">
						<div class="row">
							<div
								class="col mega-box"
								*ngFor="let childrenItem of menuItem.children">
								<div class="link-section">
									<div class="menu-title">
										<h5>
											{{ childrenItem.title | translate }}
											<span
												class="sub-arrow sub9"
												*ngIf="childrenItem.children?.length"></span>
										</h5>
									</div>
									<div class="menu-content opensubmegamenu">
										<ul *ngIf="childrenItem.children?.length">
											<li *ngFor="let childrenSubItem of childrenItem.children">
												<!-- Sub -->
												<a
													href="javascript:void(0)"
													*ngIf="childrenSubItem.type === 'sub'">
													{{ childrenSubItem.title | translate }}
													<span
														class="new-tag"
														*ngIf="childrenSubItem.badge">
														{{ childrenSubItem.badgeText | translate }}
													</span>
												</a>
												<!-- Link -->
												<a
													[routerLink]="childrenSubItem.path"
													routerLinkActive="active"
													[routerLinkActiveOptions]="{exact: true}"
													*ngIf="childrenSubItem.type === 'link'">
													{{ childrenSubItem.title | translate }}
													<span
														class="new-tag"
														*ngIf="childrenSubItem.badge">
														{{ childrenSubItem.badgeText | translate }}
													</span>
												</a>
												<!-- External Link -->
												<a
													href="{{ childrenSubItem.path }}"
													*ngIf="childrenSubItem.type === 'extLink'"
													[target]="menuItem.target ? menuItem.target : ''">
													{{ childrenSubItem.title | translate }}
													<span
														class="new-tag"
														*ngIf="childrenSubItem.badge">
														{{ childrenSubItem.badgeText | translate }}
													</span>
												</a>
												<!-- External Tab Link -->
												<a
													href="{{ childrenSubItem.path }}"
													target="_blank"
													*ngIf="childrenSubItem.type === 'extTabLink'">
													{{ childrenSubItem.title | translate }}
													<span
														class="new-tag"
														*ngIf="childrenSubItem.badge">
														{{ childrenSubItem.badgeText | translate }}
													</span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>
