import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VERSION } from '../../../../environments/version';

@Component({
	selector: 'app-footer-two',
	templateUrl: './footer-two.component.html',
	styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent implements OnInit {

	@Input() class: string;
	@Input() themeLogo: string = 'assets/images/icon/' + environment.logo; // default Logo
	@Input() mainFooter: boolean = true; // Default true
	@Input() subFooter: boolean = false; // Default false
	version = VERSION;
	env: string;

	public today: number = Date.now();

	constructor() { }

	ngOnInit(): void {
		this.env = environment.production ? 'prod' : 'dev';
	}

}
