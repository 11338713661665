<div>
	<div class="img-wrapper">
		<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '400px' }"></ngx-skeleton-loader>
	</div>
	<div class="row">
		<div class="col">
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '155px' }"></ngx-skeleton-loader>

		</div>
		<div class="col">
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '155px' }"></ngx-skeleton-loader>

		</div>
		<div class="col">
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '155px' }"></ngx-skeleton-loader>

		</div>
	</div>
</div>
