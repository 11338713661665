<div
	*ngIf="cms"
	class="cms-block">
	<h4 *ngIf="cms.showtitle">{{cms.title}}</h4>
	<a [attr.href]="cms.url">
		<img
			class="asyncbgauto"
			style="height: 385px;"
			*ngIf="cms.media && background"
			asyncbg
			[opaque]="cms.media">

		<img
			class="img-fluid"
			*ngIf="cms.media && !background"
			asyncimg
			[opaque]="cms.media">

	</a>

	<p [innerHtml]="cms.text"></p>
</div>
