import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Taxonomy } from 'yf-commerce-model-ui-lib';;

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

	@Input()
	public collections: Taxonomy[] = [];
	@Input()
	selected: number;
	@Input()
	filter: boolean;

	@Output() onselected: EventEmitter<Taxonomy> = new EventEmitter<Taxonomy>();



	public collapse: boolean = true;

	constructor() {

	}

	ngOnInit(): void {
	}


	select(c?: Taxonomy) {

		this.onselected.emit(c);

	}



}
