<owl-carousel-o
	*ngIf="slider"
	[options]="HomeSliderConfig"
	class="home-slider"
	[ngClass]="class">
	<ng-container *ngFor="let slide of slider?.slides">
		<ng-template carouselSlide>
			<div
				class="home"
				[ngClass]="textClass"
				asyncbg
				[opaque]="slide.attachid">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="slider-contain">
								<div>
									<h4>{{slide.text}}</h4>
									<h1>{{slide.title}}</h1>
									<a
										*ngIf="landing"
										[routerLink]="['/user/signin/']"
										class="btn btn-solid"
										[ngClass]="buttonClass">
										{{slide.cta}}
									</a>
									<a
										*ngIf="!landing && slide.slidetype === 'taxonomy'"
										[routerLink]="['/shop/collection/', slide.url]"
										class="btn btn-solid"
										[ngClass]="buttonClass">
										{{slide.cta}}
									</a>
									<a
										*ngIf="!landing && slide.slidetype === 'product'"
										[routerLink]="['/shop/product/', slide.url]"
										class="btn btn-solid"
										[ngClass]="buttonClass">
										{{slide.cta}}
									</a>
									<a
										*ngIf="!landing && slide.slidetype === 'standard' && !slide.url?.length"
										[routerLink]="['/home']"
										class="btn btn-solid"
										[ngClass]="buttonClass">
										{{slide.cta}}
									</a>
									<a
										*ngIf="!landing && slide.slidetype === 'standard' && slide.url?.length"
										href="{{slide.url}}"
										class="btn btn-solid"
										[ngClass]="buttonClass">
										{{slide.cta}}
									</a>
									<a
										*ngIf="!landing && slide.urlalt && slide.urlalt !== '' && slide.ctaalt && slide.ctaalt !==''"
										href="{{slide.urlalt}}"
										class="btn btn-solid ml-1"
										[ngClass]="buttonClass">
										{{slide.ctaalt}}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>
