import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontendAuthRoutingModule } from './frontend-auth-routing.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationGuardService } from './auth-guard.service';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegisterComponent } from './register/register.component';
import { ValidateEqualModule } from 'ng-validate-equal';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
	declarations: [
		LoginComponent,
		ChangePasswordComponent,
		ForgetPasswordComponent,
		RegisterComponent,
		RegisterSuccessComponent,
		ResetPasswordComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		TranslateModule.forChild(),
		FrontendAuthRoutingModule,
		FormsModule,
		SharedModule,
		ValidateEqualModule,
		ElbuildCoreComponentsModule
	],
	providers: [
		AuthenticationGuardService
	]
})
export class FrontendAuthModule { }
