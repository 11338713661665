import { Component, OnInit, Input, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { VERSION } from '../../../../environments/version';
import { ShopService } from 'yf-commerce-ui-lib';
import { CrudService, MessageService, SharedDataService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { Newsletter } from 'yf-commerce-model-ui-lib';

const COOKIECOOKIE = 'YF-cookiecookie-' + environment.storecode;
@Component({
	selector: 'app-footer-one',
	templateUrl: './footer-one.component.html',
	styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

	@Input() class: string = 'footer-light'; // Default class
	@Input() themeLogo: string = 'assets/images/icon/' + environment.logo; // Default Logo
	@Input() newsletter: boolean = true; // Default True

	showcookie = true;
	public today: number = Date.now();
	version = VERSION;
	env = environment;

	get envprod(): string {
		return environment.production ? 'prod' : 'dev';
	}

	minCart: any
	freeshipment: any;
	nl: Newsletter = new Newsletter();

	constructor(
		@Inject(LOCAL_STORAGE) private storage: StorageService,
		private shopService: ShopService,
		private nlService: CrudService<Newsletter>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private shareddataservice: SharedDataService
	) { }

	ngOnInit(): void {

		if (this.storage.has(COOKIECOOKIE)) {
			this.showcookie = false;
		}


		this.shareddataservice.currentCommondata.subscribe(res => {
			this.minCart = res?.mincart
			this.freeshipment = res?.freeshipment
		})

	}

	hidecookie(): void {
		this.showcookie = false;
		this.storage.set(COOKIECOOKIE, true);
	}

	submit(): void {
		this.nlService.createEntity(Newsletter, Newsletter.TABLE, this.nl).subscribe((data: Newsletter) => {
			this.messageService.sendSuccess(this.translateService.instant('Registration Completed'));
			this.newsletter = false;
		});
	}

}
