<!-- side-bar single product slider start -->
<div
	class="theme-card"
	*ngIf="products?.length">
	<h5 class="title-border">{{ title }}</h5>
	<owl-carousel-o
		[options]="NewProductSliderConfig"
		class="offer-slider">
		<ng-container>
			<ng-template carouselSlide>
				<div>
					<div
						class="media"
						*ngFor="let product of products | slice:0:3">
						<a
							*ngIf="product?.id"
							[routerLink]="['/shop/product/', product?.slug]">

							<img
								[size]="'xs'"
								[hidden]="!imagesready[product?.id] && product?.cover"
								asyncimg
								(ready)="imagesready[product?.id]=true"
								[opaque]="product?.cover"
								class="img-fluid w-auto"
								[placeholder]="'assets/images/product/placeholder.jpg'"
								alt>
							<ngx-skeleton-loader
								class="mr-2"
								*ngIf="!imagesready[product?.id] && product?.cover"
								[theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '130px', width : '110px' }"></ngx-skeleton-loader>
						</a>
						<div class="media-body align-self-center">

							<a
								*ngIf="product?.id"
								[routerLink]="['/shop/product/', product?.slug]">
								<h6>{{ product?.title | titlecase }}</h6>
							</a>
							<h4>
								{{ product?.price  | currency:productService.Currency?.currency:'symbol' }}
								<del *ngIf="product?.discount">
									<span class="money">
										{{ product?.baseprice | currency:productService.Currency?.currency:'symbol' }}
									</span>
								</del>
							</h4>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template carouselSlide>
				<div>

					<div
						class="media"
						*ngFor="let product of products | slice:3:6">
						<a
							*ngIf="product?.id"
							[routerLink]="['/shop/product/', product?.slug]">
							<img
								[size]="'xs'"
								[hidden]="!imagesready[product?.id] && product?.cover"
								(ready)="imagesready[product?.id]=true"
								asyncimg
								[opaque]="product?.cover"
								class="img-fluid w-auto"
								[placeholder]="'assets/images/product/placeholder.jpg'"
								alt>
							<ngx-skeleton-loader
								*ngIf="!imagesready[product?.id] && product?.cover"
								[theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '130px' }"></ngx-skeleton-loader>
						</a>
						<div class="media-body align-self-center">

							<a
								*ngIf="product?.id"
								[routerLink]="['/shop/product/', product?.slug]">
								<h6>{{ product?.title | titlecase }}</h6>
							</a>
							<h4>
								{{ product?.price  | currency:productService.Currency?.currency:'symbol' }}
								<del *ngIf="product?.discount">
									<span class="money">
										{{ product?.baseprice | currency:productService.Currency?.currency:'symbol' }}
									</span>
								</del>
							</h4>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
