import { isPlatformBrowser } from '@angular/common';
import { environment } from './../../../environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Login, AuthService, SharedDataService, MessageService } from 'elbuild-ui-lib-core';
import { Customer } from 'yf-commerce-model-ui-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { ShopCartService } from 'yf-commerce-ui-lib';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	logindata: Login = new Login();
	message: string;
	loggedUser: Customer;
	email: string;

	showRecoverpwd: boolean = true;
	redirect: any;
	env = environment;
	isbrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private authService: AuthService,
		private sharedDataService: SharedDataService,
		private router: Router,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private cartService: ShopCartService) {
		this.sharedDataService.currentLoggedUser.subscribe(data => {
			this.loggedUser = data as unknown as Customer;
			if (this.loggedUser) {
				this.router.navigateByUrl('/home');
			}
		});
		this.isbrowser = isPlatformBrowser(this.platformId);

	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.redirect = params['redirect'];
		});
	}

	login() {
		if (this.redirect) {
			this.cartService.enablemerge = false;
		} else {
			this.cartService.enablemerge = true;
		}

		this.authService.login(this.logindata).subscribe(data => {

			if (this.redirect) {
				window.location.href = environment.checkoutURL;
				// this.router.navigateByUrl('shop/checkout');
			} else {

			}

		}, ({ error }) => {
			this.messageService.sendError(error, 'Login');
		});
	}

	resetpwd() {
		this.authService.resetPassword(this.email).subscribe(result => {
			this.messageService.sendSuccess(result.msg, 'Reset password');
		}, ({ error }) => {
			this.messageService.sendError(error, 'Reset password');
		});
	}
}
