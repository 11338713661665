import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { CatalogService } from 'yf-commerce-ui-lib';
import { ShopProductService } from 'yf-commerce-ui-lib';
import { Product } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-product-box-vertical-slider',
	templateUrl: './product-box-vertical-slider.component.html',
	styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

	@Input() title: string = 'New Product'; // Default
	@Input() type: string;
	imagesready = {};
	@Input()
	public products: Product[] = [];

	public NewProductSliderConfig: any = NewProductSlider;

	constructor(public productService: ShopProductService, private catalogService: CatalogService) {


	}

	ngOnInit(): void {
		if (this.type) {
			this.loadSpecialCollection(this.type);
		}
	}



	loadSpecialCollection(slug: string) {
		this.catalogService.getSpecialCollection(slug).subscribe(data => {
			this.products = data;
		}, error => {
			// TODO
		});
	}

}
