<div [hidden]="loading">
	<div class="img-wrapper">
		<div class="lable-block">
			<span
				class="lable3"
				*ngIf="product?.newuntildate > today">
				new
			</span>
			<span
				*ngIf="product?.ispromo"
				class="lable4">

				<span
					*ngIf="product?.promopercentage"
					class="promo-percentage">
					- {{product?.promopercentage}}%
				</span>
			</span>
		</div>
		<div class="front">
			<a
				*ngIf="product"
				[routerLink]="['/shop/product/', product?.url]">
				<img
					(ready)="ready()"
					asyncimg
					[placeholder]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
					[opaque]="product?.cover"
					[alt]
					size="md"
					class="img-fluid">

			</a>
		</div>
		<div
			class="back"
			*ngIf="onHowerChangeImage">
			<!-- questa per ora non si può mettere perché i media sono un'altra chiamata -->
			<a
				*ngIf="product"
				[routerLink]="['/shop/product/', product?.url]">
				<img
					asyncimg
					size="md"
					[opaque]="product?.back"
					class="img-fluid lazy-loading"
					alt>
			</a>
		</div>
		<ul
			class="product-thumb-list"
			*ngIf="thumbnail">
			<li
				class="grid_thumb_img"
				[class.active]="ImageSrc == image?.src"
				*ngFor="let image of product?.images">
				<a
					href="javascript:void(0)"
					(mouseover)="ChangeVariantsImage(image.src)">
					<img [lazyLoad]="image.src">
				</a>
			</li>
		</ul>
		<div class="cart-info cart-wrap">

			<a
				title="Add to cart"
				(click)="addToCart(product)">
				<i class="ti-shopping-cart"></i>
			</a>
			<a
				title="{{(isInWishlist ? 'Add to wishlist' : 'Remove from wishlist') | translate}}"
				(click)="isInWishlist ? removeFromWishlist(product) : addToWishlist(product)">
				<i
					*ngIf="!isInWishlist"
					class="fa fa-heart-o"></i>
				<i
					*ngIf="isInWishlist"
					class="fa fa-heart"></i>

			</a>
			<a
				*ngIf="false"
				href="javascript:void(0)"
				title="Quick View"
				(click)="QuickView.openModal()">
				<i
					class="ti-search"
					aria-hidden="true"></i>
			</a>
			<a
				*ngIf="false"
				href="javascript:void(0)"
				title="Compare"
				(click)="addToCompare(product)">
				<i
					class="ti-reload"
					aria-hidden="true"></i>
			</a>
		</div>
	</div>
	<div class="product-detail">
		<div>

			<a
				*ngIf="product"
				[routerLink]="['/shop/product/', product?.url]">
				<h6>{{ product?.title | titlecase }}</h6>
			</a>
			<p>{{ product?.description }}</p>
			<h4>
				{{ product?.price  | currency:currency?.currency:'symbol' }}
				<del *ngIf="product?.discount">
					<span class="money">
						{{ product?.baseprice | currency:currency?.currency:'symbol' }}
					</span>
				</del>
			</h4>
			<ul
				class="color-variant"
				*ngIf="Color(product?.variants).length">
				<li
					[class]="color"
					*ngFor="let color of Color(product?.variants)"
					[ngStyle]="{'background-color': color}"
					(click)="ChangeVariants(color, product)">
				</li>
			</ul>
		</div>
	</div>
</div>
<app-skeleton-product-box *ngIf="loading"></app-skeleton-product-box>
<app-quick-view
	#quickView
	[product]="product"
	[currency]="currency"></app-quick-view>
<app-cart-modal
	#cartModal
	[product]="product"
	[currency]="currency"
	*ngIf="cartModal"></app-cart-modal>
