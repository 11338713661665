import { Component, OnInit } from '@angular/core';
import { EcommerceService, } from 'yf-commerce-ui-lib';
import { CrudService, MessageService, SharedDataService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { plainToClass } from 'class-transformer';
import { Customer, ShareRequest } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-product-share-modal',
	templateUrl: './product-share-modal.component.html',
	styleUrls: ['./product-share-modal.component.scss']
})
export class ProductShareModalComponent implements OnInit {

	loggedUser: Customer;
	request: ShareRequest = new ShareRequest();
	success: string = null;

	get title(): string {
		return `${this.translateService.instant('share-it')}`;
	}
	constructor(
		private sharedDataService: SharedDataService,
		private ecommerceService: EcommerceService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbActiveModal
	) {
		this.sharedDataService.currentLoggedUser.subscribe(data => {
			this.loggedUser = plainToClass(Customer, data);
			if (this.loggedUser) {
				this.request.sender = this.capitalize(this.loggedUser.lname) + ' ' + this.capitalize(this.loggedUser.fname)
			}

		});
	}

	ngOnInit(): void {
	}

	submit(): void {

		this.ecommerceService.shareRequest(this.request).subscribe((data: any) => {
			this.success = this.translateService.instant('share-success');
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('Error in sharing product'));
		});

	}
	dismiss(): void {
		this.modalService.dismiss();
	}

	capitalize(s) {
		return s && s[0].toUpperCase() + s.slice(1);
	}

}
