<!-- footer -->
<footer [ngClass]="class">
	<div
		class="white-layout"
		*ngIf="mainFooter">
		<section class="p-0">
			<div class="container-fluid">
				<div class="row footer-theme2 section-light footer-border">
					<div class="col">
						<div class="footer-block">
							<div class="footer-container">
								<div class="footer-title footer-mobile-title">
									<h4>about</h4>
								</div>
								<div class="footer-contant">
									<div class="footer-logo">
										<img
											[src]="themeLogo"
											alt>
									</div>
									<div class="social-white">
										<ul>
											<li>
												<a href="javascript:void(0)">
													<i
														class="fa fa-facebook"
														aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<i
														class="fa fa-google-plus"
														aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<i
														class="fa fa-twitter"
														aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<i
														class="fa fa-instagram"
														aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<i
														class="fa fa-rss"
														aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col form-p p-0">
						<div class="footer-block">
							<div class="subscribe-white">
								<h2>newsletter</h2>
								<form>
									<div class="form-group">
										<input
											type="text"
											class="form-control"
											id="exampleFormControlInput"
											placeholder="{{'enter-email' | translate}}">
										<button
											type="submit"
											class="btn btn-solid black-btn">
											{{'subscribe' | translate}}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="footer-block">
							<div class="footer-container">
								<div class="footer-title">
									<h4>
										<h4>{{'footer-store-info' | translate}}</h4>
									</h4>
								</div>
								<div class="footer-contant">
									<ul class="contact-details">
										<li>YFCommerce Demo Store, Pistoia</li>
										<li>Call Us: 123-456-7898</li>
										<li>
											Email Us:
											<a href="javascript:void(0)">Support@mail.com</a>
										</li>
										<li>Fax: 123456</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div
		class="white-layout box-layout"
		*ngIf="subFooter">
		<div class="container">
			<section class="small-section">
				<div class="row footer-theme2">
					<div class="col">
						<div class="footer-link link-white">
							<div class="footer-title">
								<h4>my account</h4>
							</div>
							<div class="footer-contant">
								<ul>
									<li>
										<a href="javascript:void(0)">mens</a>
									</li>
									<li>
										<a href="javascript:void(0)">womens</a>
									</li>
									<li>
										<a href="javascript:void(0)">clothing</a>
									</li>
									<li>
										<a href="javascript:void(0)">accessories</a>
									</li>
									<li>
										<a href="javascript:void(0)">featured</a>
									</li>
									<li>
										<a href="javascript:void(0)">service</a>
									</li>
									<li>
										<a href="javascript:void(0)">cart</a>
									</li>
									<li>
										<a href="javascript:void(0)">my order</a>
									</li>
									<li>
										<a href="javascript:void(0)">FAQ</a>
									</li>
									<li>
										<a href="javascript:void(0)">new product</a>
									</li>
									<li>
										<a href="javascript:void(0)">featured product</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div class="sub-footer black-subfooter">
		<div class="container">
			<div class="row">
				<div class="col-xl-5 col-md-7 col-sm-12">
					<div class="footer-end">
						<div class="copyright text-center">
							Copyright  ©  {{today | date: 'yyyy'}}
							<a
								id="pixinventLink"
								target="blank"
								href="https://www.elbuild.it/">
								https://www.elbuild.it/
							</a>
							, All rights reserved.
							<div class="pull-right">
								<span class="version">
									UI v. {{version.version}} {{env}} ({{version.hash}} {{version.date | date:'dd/MM/yy'}}
									<span *ngIf="version.tag">tag {{version.tag}}</span>
									)
								</span>

							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-md-5 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a href="javascript:void(0)">
									<img
										src="assets/images/icon/visa.png"
										alt>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)">
									<img
										src="assets/images/icon/mastercard.png"
										alt>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)">
									<img
										src="assets/images/icon/paypal.png"
										alt>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)">
									<img
										src="assets/images/icon/american-express.png"
										alt>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)">
									<img
										src="assets/images/icon/discover.png"
										alt>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!-- footer end -->
