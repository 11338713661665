import { isPlatformBrowser } from '@angular/common';
import { AuthService, enumToArray, Login, MessageService, Search, SharedDataService } from 'elbuild-ui-lib-core';
import { environment } from './../../../environments/environment';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Customer, CustomerType, Address, AddressType, Country, Provincia, Comune } from 'yf-commerce-model-ui-lib';
import { Router } from '@angular/router';
import { CustomerService, ShopService } from 'yf-commerce-ui-lib';
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

	env = environment;
	customer: Customer;
	address: Address;
	repeatpassword: string;
	flag = { flag1: false, flag2: false };

	showcompany = environment.showcompany;

	search: {
		country: Search,
		province: Search,
		city: Search
	};

	selected: {
		country: Country,
		province: Provincia,
		city: Comune
	};
	customerTypes: any[];
	invalidPassword: boolean;
	mktenabled: any;
	newsletterenabled: any;
	isbrowser: boolean;


	get validLenght(): boolean {
		return /(?=^.{8,}$)/.test(this.customer.plainpassword);
	}

	get validNumber(): boolean {
		return /(?=.*?[0-9])/.test(this.customer.plainpassword);
	}

	get validUppercaseChar(): boolean {
		return /(?=.*?[A-Z])/.test(this.customer.plainpassword);
	}

	get validTynyChar(): boolean {
		if (this.customer.plainpassword != null)
			return /(?=.*?[a-z])/.test(this.customer.plainpassword);
	}

	get validSpecialChar(): boolean {
		return /(?=.*?\W)/.test(this.customer.plainpassword);
	}

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private customerService: CustomerService, private router: Router,
		private authService: AuthService, private messageService: MessageService,
		private shareddataservice: SharedDataService) {

		this.isbrowser = isPlatformBrowser(this.platformId);
		this.customer = new Customer();
		this.customer.plainpassword = null;
		this.address = new Address();
		this.address.type = AddressType.BILLING;
		this.customer.type = environment.b2b ? CustomerType.COMPANY : CustomerType.INDIVIDUAL;

		this.search = {
			country: new Search('country'),
			province: new Search('province'),
			city: new Search('comuni')
		};

		this.selected = {
			country: undefined,
			province: undefined,
			city: undefined
		};

		this.shareddataservice.currentCommondata.subscribe(res => {
			this.mktenabled = res?.mktenabled;
			this.newsletterenabled = res?.newsletterenabled;
		})


	}
	ngOnInit() {
		this.customerTypes = enumToArray(CustomerType)
	}

	submit() {
		if ((this.validLenght && this.validNumber && this.validSpecialChar && this.validTynyChar && this.validUppercaseChar) === false) {
			this.invalidPassword = true;
			this.messageService.sendError('La password non rispetta i criteri di sicurezza', 'password', true)
		}
		else {
			this.invalidPassword = false;

			this.updateSelectedFields();

			this.customerService.signup(this.customer, this.address).subscribe(data => {
				if (!environment.b2b) {
					const l = new Login();
					l.username = this.customer.email;
					l.password = this.customer.password;
					this.authService.login(l).subscribe(() => { });
					this.router.navigate(['user/register-success']);
				} else {
					this.router.navigate(['user/register-success']);
				}

			}, error => {
				this.messageService.sendError(error, 'Login');
			});
		}
	}

	updateSelectedFields(): void {
		if (this.selected.country) {
			this.address.country = this.selected.country.name;
		}

		if (this.selected.province) {
			this.address.province = this.selected.province.descrizione;
		}

		if (this.selected.city) {
			this.address.city = this.selected.city.descrizione;
		}
	}

	changeProvince(): void {
		this.selected.city = undefined;
		this.search.city.addLikeFilter('pid', this.selected.province.id);
	}


}
