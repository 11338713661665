import { Meta, Title } from '@angular/platform-browser';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router, Data } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Metadata, PageTags } from 'yf-commerce-model-ui-lib';
import { ShopService, FacebookService } from 'yf-commerce-ui-lib';
import { MediaDownloadService } from 'elbuild-ui-lib-core';
import { Response } from 'express';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

const YFCHECKMETA = 'yfc:page';

@Injectable({
	providedIn: 'root'
})
export class TagService {




	private static instanceCounter = 0;
	instanceNumber: number;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(RESPONSE) private response: Response,
		private meta: Meta, private title: Title, @Inject(DOCUMENT) private doc: any,
		private assets_service: MediaDownloadService,
		private shopService: ShopService,
		private router: Router,
		private facebookService: FacebookService) {
		if (isPlatformBrowser(this.platformId)) { //FIXME
			this.instanceNumber = TagService.instanceCounter++;
			if (this.instanceNumber > 0) {
				throw new Error('TagService must be kept a singleton but more than one instance was created');
			}
		}

	}



	init() {
		this.setGTagManager();
		this.setTitle(undefined);
	}

	setPageTags(routedata: Data, url: string) {
		//const url = urls ? '/' + urls.join('/') : '';
		const yfcheck = this.meta.getTag('property="' + YFCHECKMETA + '"');
		if (!yfcheck || yfcheck.content !== url) { // lo fo solo se cambio pagina

			const title = routedata ? routedata['title'] : undefined;
			this.setTitle(title);
			this.meta.updateTag({ name: 'title', content: title });
			const description = environment.shopdescription;
			const image = environment.shopcover;
			this.meta.updateTag({ name: 'description', content: description });

			this.meta.updateTag({ property: 'og:description', content: description });
			this.meta.updateTag({ property: 'og:image', content: image });
			this.meta.addTag({ property: YFCHECKMETA, content: window.location.href });
			this.meta.updateTag({ name: 'twitter:site', content: environment.sitetitle });
			this.meta.updateTag({ property: 'og:site_name', content: environment.sitetitle });

			if (environment.fbid) {
				this.meta.updateTag({ property: 'fb:app_id', content: environment.fbid });

				if (isPlatformBrowser(this.platformId)) {
					this.facebookService.viewContent('page', window.location.href);
				}
			}


			this.loadMetadata(url);

		}
	}


	loadMetadata(url: string) {


		if (url?.length) {
			const path = url.split('?')[0].split('#')[0];
			this.shopService.getMetadata(path).subscribe((data: Metadata) => {
				if (data && data.is404) {

					this.router.navigate(['/404'], { skipLocationChange: true })
				} else if (data && data.is200) {
					this.updatePageTags(data);
				}

			}, error => {
				console.log('tag service error', error);
			});


		}
	}

	updatePageTags(pagetags: PageTags) {
		this.setTitle(pagetags.metatitle);
		this.meta.addTag({ property: YFCHECKMETA, content: pagetags.metatitle });
		this.meta.updateTag({ name: 'description', content: pagetags.metadescription });
		this.meta.updateTag({ property: 'og:description', content: pagetags.metadescription });

		this.meta.updateTag({ name: 'twitter:url', content: environment.shopURL + pagetags.url });
		this.meta.updateTag({ property: 'og:url', content: environment.shopURL + pagetags.url });


		//TODO
		/**<link rel="canonical" href = "https://example.com/dresses/green-dresses" />
		 *
		 */




		if (pagetags.metaimage) {
			this.meta.updateTag({ name: 'twitter:image', content: pagetags.metaimage });
			this.meta.updateTag({ property: 'og:image', content: pagetags.metaimage });
		}
		if (pagetags.metakeyword) {
			this.meta.updateTag({ name: 'keyword', content: pagetags.metaimage });
		}





	}




	private setGTagManager() {
		if (environment.analytics && environment.analytics != '') {
			const s = this.doc.createElement('script');
			s.type = 'text/javascript';
			s.innerHTML = 'ga(\'create\', \'' + environment.analytics + '\', \'auto\');';
			const head = this.doc.getElementsByTagName('head')[0];
			head.appendChild(s);
		}
	}

	private setTitle(page) {
		let title = environment.shopname; // TODO mettere in init


		if (page) {
			title = title + ' - ' + page;
		}
		this.title.setTitle(title);
		this.meta.updateTag({ property: 'og:title', content: title });
		this.meta.updateTag({ name: 'twitter:title', content: title });
	}
}
