<ng-container *ngIf="isbrowser">
	<app-header-one *ngIf="env.theme !== 'flower'"></app-header-one>
	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>

	<app-breadcrumb
		[title]="'Registrati'"
		[breadcrumb]="'Registrati'"></app-breadcrumb>
	<!-- section start -->
	<section class="register-page section-b-space">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h3>{{'register-page.title' | translate}}</h3>

					<ng-template
						[ngIf]="env.b2b"
						id="descr">
						<h4 innerHTML="{{'register-page.descr.h4' | translate}}"></h4>
						<p innerHTML="{{'register-page.descr.p1' | translate}}"></p>
						<p innerHTML="{{'register-page.descr.p2' | translate}}"></p>
						<a
							class="btn btn-link"
							[routerLink]="['/user/signin']"
							innerHTML="{{'register-page.descr.a' | translate}}"></a>

					</ng-template>
					<div class="theme-card">
						<form
							#registerForm="ngForm"
							(ngSubmit)="registerForm.valid && submit()"
							class="theme-form register">
							<div class="form-row">
								<div class="col-md-6">
									<label>{{'labels.name' | translate}}</label>
									<input
										name="fname"
										#fname="ngModel"
										[(ngModel)]="customer.fname"
										type="text"
										class="form-control"
										id="fname"
										placeholder="{{ 'labels.name' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && fname.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && fname.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
								<div class="col-md-6">
									<label>{{'labels.lname' | translate}}</label>
									<input
										name="lname"
										#lname="ngModel"
										[(ngModel)]="customer.lname"
										type="text"
										class="form-control"
										id="lname"
										placeholder="{{ 'labels.lname' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && lname.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && lname.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-6">
									<label>{{'labels.email' | translate}}</label>
									<input
										name="email"
										[(ngModel)]="customer.email"
										#email="ngModel"
										email
										type="text"
										class="form-control"
										id="email"
										placeholder="{{ 'labels.email' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && email.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && email.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>
								<div class="col-md-6">
									<label>{{'labels.mobile' | translate}}</label>
									<input
										name="mobile"
										[(ngModel)]="customer.mobile"
										#mobile="ngModel"
										type="text"
										class="form-control"
										id="mobile"
										placeholder="{{ 'labels.mobile' | translate }}"
										[ngClass]="{'is-invalid': registerForm.submitted && mobile.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && mobile.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>
								<div
									*ngIf="env.b2b"
									class="col-md-6">
									<label>{{'labels.fullname' | translate}}</label>
									<input
										name="cname"
										[(ngModel)]="customer.fullname"
										#cname="ngModel"
										type="text"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.fullname' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && cname.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && email.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div
								*ngIf="env.b2b"
								class="form-row">
								<div class="col-md-10">
									<label>{{'labels.address' | translate}}</label>
									<input
										name="address"
										[(ngModel)]="address.address"
										#addres="ngModel"
										type="text"
										class="form-control"
										id="email"
										placeholder="{{ 'labels.address' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && addres.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && addres.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
								<div class="col-md-2">
									<label>{{'labels.streetno' | translate}}</label>
									<input
										name="streetno"
										[(ngModel)]="address.streetno"
										#streetno="ngModel"
										type="text"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.streetno' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && streetno.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && streetno.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div
								*ngIf="env.b2b"
								class="form-row">

								<div class="col-md-4 autocomplete">
									<label>{{'labels.country' | translate}}</label>
									<app-entity-autocomplete
										[randomname]="true"
										entityName="country"
										entityField="name"
										entityFilterType="like"
										[entitySearch]="search.country"
										name="country"
										required
										[(ngModel)]="selected.country"
										placeholder="{{ 'labels.country' | translate }}"
										#country="ngModel"
										[ngClass]="{'is-invalid': registerForm.submitted && country.invalid}">
									</app-entity-autocomplete>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && country.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
								<div class="col-md-4 autocomplete">
									<label>{{'labels.province' | translate}}</label>
									<app-entity-autocomplete
										[randomname]="true"
										entityName="province"
										entityField="descrizione"
										entityFilterType="like"
										[entitySearch]="search.province"
										name="province"
										required
										#province="ngModel"
										[(ngModel)]="selected.province"
										placeholder="{{ 'labels.province' | translate }}"
										[ngClass]="{'is-invalid': registerForm.submitted && province.invalid}"
										(ngModelChange)="changeProvince()">
									</app-entity-autocomplete>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && province.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
								<div class="col-md-4 autocomplete">
									<label>{{'labels.city' | translate}}</label>
									<app-entity-autocomplete
										[randomname]="true"
										entityName="comuni"
										entityField="descrizione"
										entityFilterType="like"
										[entitySearch]="search.city"
										[disabled]="!selected.province?.id"
										name="city"
										required
										[(ngModel)]="selected.city"
										#city="ngModel"
										placeholder="{{ 'labels.city' | translate }}"
										[ngClass]="{'is-invalid': registerForm.submitted && city.invalid}">
									</app-entity-autocomplete>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && city.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>

							<div
								*ngIf="env.b2b"
								class="form-row">

								<div class="col-md-6">
									<label>{{'labels.vat' | translate}}</label>
									<input
										name="piva"
										[(ngModel)]="customer.vat"
										#vat="ngModel"
										type="text"
										class="form-control"
										id="review"
										required
										pattern="^[0-9]{9,}$"
										placeholder="{{ 'labels.vat' | translate }}"
										[required]="customer.isCompany"
										[ngClass]="{'is-invalid': registerForm.submitted && vat.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && vat.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>
								<div class="col-md-6">
									<label>{{'labels.cf' | translate}}</label>
									<input
										name="cf"
										[(ngModel)]="customer.cf"
										#cf="ngModel"
										type="text"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.cf' | translate }}"
										required
										pattern="^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$"
										[ngClass]="{'is-invalid': registerForm.submitted && cf.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && cf.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>
							</div>
							<div
								*ngIf="env.b2b"
								class="form-row">
								<div class="col-md-6">
									<label>{{'labels.pec' | translate}}</label>
									<input
										name="pec"
										[(ngModel)]="customer.pec"
										#pec="ngModel"
										email
										type="text"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.pec' | translate }}"
										[required]="customer.isCompany"
										[ngClass]="{'is-invalid': registerForm.submitted && pec.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && pec.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>
								<div class="col-md-6">
									<label>{{'labels.codiceunico' | translate}}</label>
									<input
										name="codiceunico"
										[(ngModel)]="customer.codiceunico"
										#codiceunico="ngModel"
										type="text"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.codiceunico' | translate }}"
										[required]="customer.isCompany"
										[ngClass]="{'is-invalid': registerForm.submitted && codiceunico.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && codiceunico.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div
								*ngIf="!env.b2b && showcompany"
								class="form-row">

								<div class="col-md-6">
									<label>{{'register-page.type' | translate}}</label>
									<select
										required
										class="form-control custom-select"
										[(ngModel)]="customer.type"
										#type="ngModel"
										name="type"
										[ngClass]="{'is-invalid': registerForm.submitted && type.invalid}">
										<option
											*ngFor="let t of customerTypes"
											[value]="t.value">
											{{'labels.'+ t.value | lowercase | translate}}
										</option>
									</select>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && type.invalid">
										{{'errors.invalid' | translate}}
									</div>
								</div>

							</div>
							<div class="form-row mt-2">
								<div class="col-md-6">
									<label>{{'labels.password' | translate}}</label>
									<input
										name="password"
										[(ngModel)]="customer.plainpassword"
										#password="ngModel"
										type="password"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.password' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && password.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && password.invalid && !invalidPassword">
										{{'errors.invalid' | translate}}
									</div>
									<div
										class="invalid-feedback d-block"
										*ngIf="invalidPassword">
										<span>La password deve:</span>
										<ul class="list">
											<li [ngClass]="validLenght ? 'green' : 'red'">Contenere almeno otto caratteri;</li>
											<br>
											<li [ngClass]="validUppercaseChar ? 'green' : 'red'">Contenere almeno una lettera maiuscola;</li>
											<br>
											<li [ngClass]="validTynyChar ? 'green' : 'red'">Contenere almeno una lettere minuscola;</li>
											<br>
											<li [ngClass]="validNumber ? 'green' : 'red'">Contenere almeno un numero;</li>
											<br>
											<li [ngClass]="validSpecialChar ? 'green' : 'red'">Contenere almeno un carattere speciale;</li>
											<br>
										</ul>
									</div>

								</div>
								<div class="col-md-6">
									<label>
										{{'labels.repeat-password' | translate}}
									</label>
									<input
										ngValidateEqual="password"
										#rpassword="ngModel"
										[(ngModel)]="repeatpassword"
										name="rpassword"
										type="password"
										class="form-control"
										id="review"
										placeholder="{{ 'labels.repeat-password' | translate }}"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && rpassword.invalid}">
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && rpassword.invalid && !rpassword.hasError('notEqual')">
										{{'errors.required' | translate}}
									</div>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && rpassword.hasError('notEqual')">
										{{'errors.not-match' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<br>
							</div>
							<div class="form-row">
								<div class="col-md-12">
									<input
										[(ngModel)]="flag.flag1"
										name="flag1"
										#flag1="ngModel"
										type="checkbox"
										class="mr-3"
										id="exampleCheck1"
										required
										[ngClass]="{'is-invalid': registerForm.submitted && flag1.invalid}">

									<label
										class="form-check-label"
										for="exampleCheck1"
										innerHTML="{{'register-page.flags.1' | translate}}">

									</label>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && flag1.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-12">
									<input
										required
										name="flag2"
										[(ngModel)]="flag.flag2"
										#flag2="ngModel"
										type="checkbox"
										class="mr-3"
										id="exampleCheck2"
										[ngClass]="{'is-invalid': registerForm.submitted && flag2.invalid}">

									<label
										class="form-check-label"
										for="exampleCheck2"
										innerHTML="{{'register-page.flags.2' | translate}}">

									</label>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && flag2.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div
								class="form-row"
								*ngIf="mktenabled">
								<div class="col-md-12">
									<input
										name="mkt"
										[(ngModel)]="customer.mkt"
										#mkt="ngModel"
										type="checkbox"
										class="mr-3"
										id="mkt"
										[ngClass]="{'is-invalid': registerForm.submitted && mkt.invalid}">

									<label
										class="form-check-label"
										for="mkt"
										innerHTML="{{'register-page.flags.mkt' | translate}}">

									</label>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && mkt.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div
								class="form-row"
								*ngIf="newsletterenabled">
								<div class="col-md-12">
									<input
										name="newsletter"
										[(ngModel)]="customer.newsletter"
										#newsletter="ngModel"
										type="checkbox"
										class="mr-3"
										id="newsletter"
										[ngClass]="{'is-invalid': registerForm.submitted && newsletter.invalid}">

									<label
										class="form-check-label"
										for="newsletter"
										innerHTML="{{'register-page.flags.newsletter' | translate}}">

									</label>
									<div
										class="invalid-feedback"
										*ngIf="registerForm.submitted && newsletter.invalid">
										{{'errors.required' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<br>
								<div class="col-md-12">
									<button
										type="submit"
										class="btn btn-solid float-right">
										{{'buttons.signin' | translate}}
									</button>
								</div>
							</div>

							<!-- Section ends -->
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</ng-container>
