<div>

	<div class="product-detail">
		<h4>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '350px', 'border-radius': '5px',  height: '25px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
		</h4>
		<h5>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '5px',  height: '12px'}"></ngx-skeleton-loader>
		</h5>
		<h6>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '70px', 'border-radius': '5px',  height: '14px'}"></ngx-skeleton-loader>
		</h6>
	</div>
</div>
