import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-product-details',
  templateUrl: './skeleton-product-details.component.html',
  styleUrls: ['./skeleton-product-details.component.scss']
})
export class SkeletonProductDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
