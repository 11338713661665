<!-- header start -->
<header
	class="sticky"
	[ngClass]="class">
	<div class="mobile-fix-option"></div>
	<div
		class="top-header"
		*ngIf="topbar">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<div class="header-contact">
						<ul>
							<li translate>topbar-title</li>
							<li>
								<i
									class="fa fa-phone"
									aria-hidden="true"></i>
								{{'call-us-num' | translate}}
							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-6 text-right">
					<ul class="header-dropdown">
						<li
							class="compare"
							*ngIf="false">
							<a [routerLink]="['/shop/compare']">
								<i
									class="fa fa-random"
									aria-hidden="true"></i>
								Compare
							</a>
						</li>
						<li class="mobile-wishlist">
							<a [routerLink]="['/shop/wishlist']">
								<i
									class="fa fa-heart"
									aria-hidden="true"></i>
								Wishlist
							</a>
						</li>
						<li
							class="mobile-account ml-2"
							[ngClass]="{'onhover-dropdown': loggedUser && !ismobile}">
							<a
								[routerLink]="['/user/signin']"
								*ngIf="!loggedUser">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{'buttons.login' | translate}}
							</a>
							<a *ngIf="loggedUser && !ismobile">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{loggedUser.fullname ? loggedUser.fullname : (loggedUser.fname + ' ' + loggedUser.lname)}}
							</a>
							<a *ngIf="loggedUser && ismobile">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{loggedUser.fullname ? loggedUser.fullname : (loggedUser.fname + ' ' + loggedUser.lname)}}
							</a>
							<!-- My Account -->
							<ul
								*ngIf="loggedUser && !ismobile"
								class="onhover-show-div">
								<li>
									<a [href]="myaccountURL">Account</a>
								</li>
								<li>
									<a (click)="logout()">Logout</a>
								</li>
							</ul>
						</li>

					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="main-menu border-section border-top-0">
					<div class="brand-logo layout2-logo">
						<a [routerLink]="['/']">
							<img
								[src]="themeLogo"
								class="img-fluid"
								alt="logo">
						</a>
					</div>
					<div>
						<form
							class="form_search"
							role="form">
							<input
								id="query search-autocomplete"
								type="search"
								placeholder="Find the best for your..."
								class="nav-search nav-search-field"
								aria-expanded="true">
							<button
								type="button"
								name="nav-submit-button"
								class="btn-search">
								<i class="ti-search"></i>
							</button>
						</form>
					</div>
					<div class="menu-right pull-right">
						<app-settings></app-settings>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="main-nav-center">
					<app-menu></app-menu>
				</div>
			</div>
		</div>
	</div>
</header>
<!-- header end -->
