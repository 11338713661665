<div class="media">
	<a [routerLink]="['/shop/product/', product?.url]">
		<img
			asyncimg
			size="md"
			[placeholder]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
			[opaque]="product?.cover"
			class="img-fluid lazy-loading"
			alt="{{ product.images[0]?.alt }}">
	</a>
	<div class="media-body align-self-center">
		<div class="rating"></div>
		<a [routerLink]="['/shop/product/', product?.url]">
			<h6>{{ product.title | titlecase }}</h6>
		</a>
		<h4>
			{{ product?.price  | currency:currency?.currency:'symbol' }}
			<del *ngIf="product?.discount">
				<span class="money">
					{{ product?.baseprice | currency:currency?.currency:'symbol' }}
				</span>
			</del>
		</h4>
		<ul
			*ngIf="false"
			class="color-variant">
			<li
				[class]="color"
				*ngFor="let color of Color(product?.variants)"
				[ngStyle]="{'background-color': color}"
				(click)="ChangeVariants(color, product)">
			</li>
		</ul>
	</div>
</div>
