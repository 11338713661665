export const environment = {
	shopname: 'Dev Shop',
	shopdescription: 'Dev Shop YFCommerce',
	production: true,
	analytics: 'G-8VGXSLST64',
	fbid: '875997026463646',
	theme: 'fashion',
	collectionlayout: 'leftsidebar',
	collectionpagesize: 16,
	productlayout: 'leftsidebar',
	themecolor: '#eb8934',
	logo: 'logo.png',
	shopcover: 'logo.png',
	b2b: false,
	maxprice: 200,
	showpricefilter: true,
	storecode: 'dev',
	leftmenu: false,
	languages: [{
		name: 'English',
		code: 'en'
	}, {
		name: 'Italiano',
		code: 'it'
	}],
	defaultlang: 'it',
	showvat: false,
	showcompany: false,
	defaultcountry: 'Italia',
	onlydefaultcountry: false,
	completenewsletter: true,
	roottaxonomy: 'home',
	myaccountURL: 'https://yfc-front.6.elbuild.com/my/',
	checkoutURL: 'https://yfc-front.6.elbuild.com/secure/',
	shopURL: 'https://yfc-front.6.elbuild.com',
	processport: 4002,
	apiprefix: '/ws/',
	relativeapiurl: '/ws/',
	apiurl: 'http://localhost:9080/yfctest-api/v1/',
	cacheablepages: ['/', '/list'],
	cacheactive: true,
	cacheprefix: 'yfctestssr_',
	cacheredis: true,
	cacheexpire: 10, // seconds
	redisport: 6379,
	redishost: '127.0.0.1',
	sitetitle: ' | ELbuild',
	folder: '',
	lang: 'it',
	cachecontent: false,
	brandinfopage: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
