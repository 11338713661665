<ngx-loading-bar></ngx-loading-bar>
<div
	id="loader"
	*ngIf="!isbrowser">
	<!-- Here is your code (spinner, text , etc...) -->
	<div class="lds-ellipsis">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>
<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<app-layout-box *ngIf="false"></app-layout-box>
