import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
	{
		path: 'signin',
		component: LoginComponent,
		data: {
			title: 'Login'
		}
	},
	{
		path: 'register',
		component: RegisterComponent,
		data: {
			title: 'Register'
		}
	},
	{
		path: 'register-success',
		component: RegisterSuccessComponent,
		data: {
			title: 'Register Success'
		}
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent,
		data: {
			title: 'Change password'
		}
	},
	{
		path: 'forgot-password',
		component: ForgetPasswordComponent,
		data: {
			title: 'Forgot password'
		}
	},
	{
		path: 'reset-password/:token',
		component: ResetPasswordComponent,
		data: {
			title: 'Reset Password'
		}
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FrontendAuthRoutingModule { }
