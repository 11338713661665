import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VERSION } from '../../../../environments/version';

@Component({
	selector: 'app-footer-four',
	templateUrl: './footer-four.component.html',
	styleUrls: ['./footer-four.component.scss']
})
export class FooterFourComponent implements OnInit {

	@Input() class: string = 'footer-light'; // Default class
	@Input() themeLogo: string = 'assets/images/icon/' + environment.logo; // Default Logo
	public today: number = Date.now();
	version = VERSION;
	env: string;

	constructor() { }

	ngOnInit(): void {
		this.env = environment.production ? 'prod' : 'dev';
	}

}
