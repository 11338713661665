import { isPlatformBrowser } from '@angular/common';
import { environment } from './../../../environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
	selector: 'app-register-success',
	templateUrl: './register-success.component.html',
	styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {

	env = environment;
	isbrowser: boolean;
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		this.isbrowser = isPlatformBrowser(platformId);
	}

	ngOnInit(): void {
	}

}
