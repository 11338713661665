<div
	class="loading"
	*ngIf="!wishlist">
	<i class="fa fa-circle-o-notch fa-spin fa-2x"></i>
</div>

<ng-template [ngIf]="wishlist">
	<div class="row">
		<div class="col-sm-12">
			<div
				*ngIf="!wishlist?.length"
				class="mb-4">
				{{'empty-wishlist' | translate}}
			</div>
			<div class="table-scroll">
				<table
					*ngIf="wishlist?.length"
					class="table cart-table table-responsive-xs  wishlist-table">
					<thead>
						<tr class="table-head">
							<th scope="col">{{"image" | translate}}</th>
							<th scope="col">{{"product name" | translate}}</th>
							<th scope="col">{{"price" | translate}}</th>
							<th scope="col">{{"availability" | translate}}</th>
							<th scope="col">{{"action" | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let wl of wishlist">
							<td>
								<a [routerLink]="'/shop/product/'+ wl.url">
									<img
										asyncimg
										[placeholder]="'assets/images/product/placeholder.jpg'"
										[opaque]="wl?.cover"
										[alt]
										size="md"
										class="img-fluid wimg">
								</a>
							</td>
							<td>
								<a [routerLink]="'/shop/product/'+ wl.url">{{ wl.name | titlecase }}</a>

							</td>
							<td>
								<span class="td-color">{{ wl.price | currency:'EUR' }}</span>
							</td>
							<td>
								<p>in stock</p>
							</td>
							<td>
								<a
									(click)="removeItem(wl)"
									class="icon mr-3 link">
									<i class="ti-close"></i>
								</a>
								<a
									(click)="addToCart(wl)"
									class="cart link">
									<i class="ti-shopping-cart"></i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="row wishlist-buttons">
		<div class="col-12">
			<button
				*ngIf="wishlist?.length"
				class="btn btn-solid float-left"
				(click)="shareRequest()">
				<span class="title-font">
					{{'share-it' | translate}}
					<i
						class="fa fa-share-alt"
						aria-hidden="true"></i>
				</span>
			</button>
			<a
				[routerLink]="['/print-wishlist']"
				target="_blank"
				class="btn btn-solid">
				{{'print-wishlist' | translate}}
			</a>
			<a
				[routerLink]="['/home']"
				class="btn btn-solid">
				{{'shopping.continue' | translate}}
			</a>

		</div>
	</div>
</ng-template>
