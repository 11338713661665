import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { CrudService, Search } from 'elbuild-ui-lib-core';
import { CMS } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-cms',
	templateUrl: './cms.component.html',
	styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

	@Input()
	slug: string;

	@Input()
	background: boolean = true;

	cms: CMS;

	constructor(
		private cmsService: CrudService<CMS>
	) { }

	ngOnInit() {
		if (this.slug) {
			this.loadCMS();
		}
	}

	loadCMS(): void {
		// TO DO da modificare con endpoint giusto
		const search = new Search('cms');
		search.addSimpleFilter('slug', this.slug);
		search.addSimpleFilter('lang', environment.defaultlang);

		this.cmsService.searchEntities(CMS, 'cms', search, search.page).subscribe((data: CMS[]) => {
			this.cms = data[0];
		});
	}

}
