import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VERSION } from '../../../../environments/version';

@Component({
	selector: 'app-footer-three',
	templateUrl: './footer-three.component.html',
	styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {

	@Input() class: string; // Default class
	@Input() mainFooter: boolean = true; // Default true
	@Input() subFooter: boolean = false; // Default false
	@Input() themeLogo: string = 'assets/images/icon/' + environment.logo; // Default Logo

	public today: number = Date.now();
	version = VERSION;
	env: string;

	constructor() { }

	ngOnInit(): void {
		this.env = environment.production ? 'prod' : 'dev';
	}

}
