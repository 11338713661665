import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Brand } from 'yf-commerce-model-ui-lib';
import { LogoSlider } from '../../../shared/data/slider';

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

	@Input() logos: Brand[] = [];

	rootcat = environment.roottaxonomy;

	constructor() { }

	ngOnInit(): void {
	}

	public LogoSliderConfig: any = LogoSlider;

}
