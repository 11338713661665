<header
	class="sticky"
	[ngClass]="class"
	[class.fixed]="stick && sticky">
	<div class="mobile-fix-option"></div>
	<div
		class="top-header"
		*ngIf="topbar">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<div class="header-contact">
						<div class="header-contact">
							<ul>
								<li>
									<app-cms [slug]="'home-intro'"></app-cms>

								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-6 text-right">
					<ul class="header-dropdown">
						<li class="mobile-wishlist">
							<a
								ng-reflect-router-link="/pages/register"
								[routerLink]="['/user/signin']">
								Login
							</a>
						</li>
						<li class="mobile-wishlist">|</li>
						<li class="mobile-wishlist">
							<a
								ng-reflect-router-link="/pages/register"
								[routerLink]="['/user/register']">
								Registrati
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="main-menu">
					<div class="menu-left">
						<div class="brand-logo">
							<a
								routerLink="/home"
								id="headerlogo">
								<img
									width="180"
									[src]="themeLogo"
									class="img-fluid"
									alt="logo">
							</a>
						</div>
					</div>
					<div class="menu-right pull-right">
						<div>
							<app-menu [landing]="true"></app-menu>
						</div>
						<div>
							<app-settings [landing]="true"></app-settings>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
