<form
	class="theme-form mb-0"
	#infoForm="ngForm"
	(ngSubmit)="infoForm.valid && submit()"
	*ngIf="request">
	<div class="modal-content">
		<div class="modal-header">
			<h5
				class="modal-title"
				id="exampleModalLabel">
				{{title}}
			</h5>
			<button
				type="button"
				class="close"
				data-dismiss="modal"
				aria-label="Close"
				(click)="dismiss()">
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="modal-body">
			<ng-template [ngIf]="!success">
				<div class="form-row">
					<div class="col-md-4">
						<label>{{ 'sender' | translate }}*</label>
						<input
							[(ngModel)]="request.sender"
							#sender="ngModel"
							name="sender"
							sender
							type="text"
							required
							class="form-control"
							placeholder="{{ 'labels.name' | translate }}"
							[ngClass]="{'is-invalid': infoForm.submitted && sender.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="infoForm.submitted && sender.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<!-- Destinatario -->
					<div class="col-md-4">
						<label>{{ 'labels.email' | translate }}*</label>
						<input
							[(ngModel)]="request.email"
							#requestemail="ngModel"
							name="requestemail"
							type="text"
							required
							email
							class="form-control"
							placeholder="{{ 'labels.email' | translate }}"
							[ngClass]="{'is-invalid': infoForm.submitted && requestemail.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="infoForm.submitted && requestemail.invalid">
							{{'errors.invalid' | translate}}
						</div>
					</div>
				</div>

			</ng-template>
			<ng-template [ngIf]="success">
				<h5>{{success}}</h5>
			</ng-template>

		</div>
		<div class="modal-footer">
			<div class="col-md-12">
				<button
					class="btn btn-sm btn-default mr-1"
					type="button"
					(click)="dismiss()">
					{{(success ? 'buttons.close' : 'buttons.cancel') | translate}}
				</button>
				<button
					*ngIf="!success"
					class="btn btn-sm btn-solid"
					type="submit">
					{{'buttons.submit' | translate}}
				</button>
			</div>
		</div>
	</div>
</form>
