<ng-container ngIf="isbrowser">
	<app-header-one *ngIf="env.theme !== 'flower'"></app-header-one>
	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>

	<!-- thank-you section start -->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div
						class="success-text"
						*ngIf="env.b2b">
						<i class="fa fa-check-circle"></i>
						<h2>
							{{'register-success-page.title' | translate}}
						</h2>
						<p>
							{{'register-success-page.p1' | translate}}
						</p>
						<p>
							{{'register-success-page.p2' | translate}}
						</p>
						<a
							class="btn btn-link mt-3"
							[routerLink]="'/'">
							{{'buttons.home' | translate}}
						</a>
					</div>
					<div
						class="success-text"
						*ngIf="!env.b2b"
						id="completed">
						<i class="fa fa-check-circle"></i>
						<h2>
							{{'register-success-page.title' | translate}}
						</h2>
						<p>
							{{'register-success-page.p1' | translate}}
						</p>
						<a
							class="btn btn-link mt-3"
							[routerLink]="'/'">
							{{'buttons.home' | translate}}
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Section ends -->
</ng-container>
