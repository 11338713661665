import { isPlatformBrowser } from '@angular/common';
import { environment } from './../../../environments/environment.b2b';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'elbuild-ui-lib-core';
import { CustomerService } from 'yf-commerce-ui-lib';

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
	env = environment;
	email: string;
	done: boolean;
	isbrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private customerService: CustomerService,
		private messageService: MessageService,
		private translateService: TranslateService) {
		this.isbrowser = isPlatformBrowser(this.platformId);
	}

	ngOnInit(): void {
	}


	reset() {
		this.customerService.forgotPassword(this.email).subscribe(data => {
			this.done = true;
		}, error => {
			this.messageService.sendError(error, this.translateService.instant('error'));

			console.log('error', error);
		});
	}

}
