import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Breadcrumb, SharedDataService } from 'elbuild-ui-lib-core';
import { Wishlist, Product, Composition } from 'yf-commerce-model-ui-lib';
import { ProductShareModalComponent } from 'src/app/shop/product/product-base/product-share-modal/product-share-modal.component';
import { ShopCartService, WishlistService, ShopProductService, User } from 'yf-commerce-ui-lib';

@Component({
	selector: 'app-wishlist',
	templateUrl: './wishlist.component.html',
	styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {


	wishlist: Product[];

	breadcrumb: Breadcrumb = new Breadcrumb();
	loggedUser: User
	constructor(
		private wishlistService: WishlistService,
		private sharedDataService: SharedDataService,
		private cartService: ShopCartService,
		public productService: ShopProductService,
		private modalService: NgbModal
	) {

		this.sharedDataService.currentLoggedUser.subscribe(u => {
			this.loggedUser = u as unknown as User;
			if (this.loggedUser) {
			}
		});
	}

	ngOnInit(): void {


		this.wishlistService.products.subscribe((data: Product[]) => {
			this.wishlist = data;
		});

	}




	removeItem(wl: Product): void {
		this.wishlistService.remove(wl.id);
	}

	addToCart(wl: Wishlist): void {
		const c = new Composition();
		c.productid = wl.productid;
		this.cartService.add(c, undefined, 1);
	}

	shareRequest(): void {
		const modal = this.modalService.open(ProductShareModalComponent, { centered: true, size: 'lg' });
		modal.componentInstance.request.sharetype = 'wishlist';


	}

}
