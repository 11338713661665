<!-- modal popup start -->
<ng-template
	class="theme-modal"
	#newsletter
	let-modal>
	<div class="modal-content">
		<div class="modal-body modal7">
			<div class="container-fluid p-0">
				<div class="row">
					<div class="col-12">
						<div class="modal-bg">
							<button
								type="button"
								class="close"
								aria-label="Close"
								(click)="modal.dismiss('Cross click')">
								<span aria-hidden="true">×</span>
							</button>
							<div class="offer-content">
								<img
									src="assets/images/offer-banner-1.png"
									class="img-fluid"
									alt>
								<h2>newsletter</h2>
								<form
									#nlForm="ngForm"
									(ngSubmit)="nlForm.valid && submit()"
									class="auth-form needs-validation">
									<div
										class="row"
										*ngIf="env.completenewsletter">
										<div class="col-md-6">
											<input
												type="text"
												class="form-control"
												name="NOME"
												[(ngModel)]="nl.fname"
												#nlfname="ngModel"
												placeholder="{{'labels.fname' | translate}}"
												[ngClass]="{'is-invalid': nlForm.submitted && nlfname.invalid}"
												required>
										</div>
										<div class="col md-6">
											<input
												type="text"
												class="form-control"
												name="COGNOME"
												[(ngModel)]="nl.lname"
												#nllname="ngModel"
												placeholder="{{'labels.lname' | translate}}"
												[ngClass]="{'is-invalid': nlForm.submitted && nllname.invalid}"
												required>
										</div>
									</div>
									<br>
									<div class="row">
										<div class="col-md-12">
											<input
												type="text"
												class="form-control"
												name="EMAIL"
												[(ngModel)]="nl.email"
												#nlemail="ngModel"
												email
												placeholder="{{'labels.email' | translate}}"
												[ngClass]="{'is-invalid': nlForm.submitted && nlemail.invalid}"
												required>
										</div>
									</div>
									<br>

									<div class="row">
										<div class="col-md-12">
											<button
												type="submit"
												class="btn btn-solid">
												{{'subscribe' | translate}}
											</button>
										</div>

									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- modal popup end -->
