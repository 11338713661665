<!-- Start Categories List -->
<div
	class="collection-collapse-block border-0"
	[class.open]="collapse">
	<h3
		class="collapse-block-title"
		(click)="collapse = !collapse">
		{{ 'category' | translate }}
	</h3>
	<div class="collection-collapse-block-content">
		<div class="collection-brand-filter">

			<ul class="category-list">
				<li
					[ngClass]="{'active': c.id == selected}"
					*ngFor="let c of collections">
					<a
						*ngIf="filter"
						(click)="select(c)">
						{{ c.title }}
					</a>
					<a
						*ngIf="!filter"
						[routerLink]="['/shop/collection/', c.slug]">
						{{ c.title }}
					</a>
				</li>
				<li *ngIf="selected">
					<a (click)="select()">{{ 'all' | translate }}</a>
				</li>

			</ul>
		</div>
	</div>
</div>
<!-- End Categories List -->
