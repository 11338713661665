import { Component, OnInit } from '@angular/core';
import { HeaderBaseComponent } from '../header-base/header-base.component';
import { SharedDataService, AuthService, CrudService } from 'elbuild-ui-lib-core';
import { Router } from '@angular/router';
import { ShopCartService, WishlistService } from 'yf-commerce-ui-lib';
import { Customer } from 'yf-commerce-model-ui-lib';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-header-light',
	templateUrl: './header-light.component.html',
	styleUrls: ['./header-light.component.scss']
})
export class HeaderLightComponent extends HeaderBaseComponent implements OnInit {

	constructor(
		sharedDataService: SharedDataService,
		authService: AuthService,
		router: Router,
		cartService: ShopCartService,
		customerService: CrudService<Customer>,
		wishlistService: WishlistService,
		deviceService: DeviceDetectorService) {
		super(sharedDataService, authService, router, cartService, customerService, wishlistService, deviceService);

	}

	ngOnInit(): void {

	}

}
