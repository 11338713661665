// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	instagram_token: 'INSTAGRAM_TOKEN',
	stripe_token: 'STRIPE_PUBLISHABLE_KEY',
	paypal_token: 'PAYPAL_CLIENT_ID',
	theme: 'fashion',
	collectionlayout: 'nosidebar',
	productlayout: 'leftsidebar',
	themecolor: '#fa869b',
	logo: 'logo.png',
	b2b: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
