import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

	private status;

	constructor(@Optional() @Inject(RESPONSE) private response: Response,
		private route: ActivatedRoute,
		private router: Router) {
		this.status = route.snapshot.data['status'] || 404;


		if (this.response) {
			this.response.status(this.status);
		}

		this.router.navigate(['pages/404'], { skipLocationChange: true })
	}

	ngOnInit(): void {

	}

}
