<!-- footer section -->
<footer [class]="class">
	<div
		class="light-layout"
		*ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>{{'newsletter-cta' | translate}}</h4>
								<p>{{'newsletter-text' | translate}}</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form
							#nlForm="ngForm"
							(ngSubmit)="nlForm.valid && submit()"
							class="auth-form needs-validation">
							<div
								class="row"
								*ngIf="env.completenewsletter">
								<div class="col-md-6">
									<input
										type="text"
										class="form-control"
										name="NOME"
										[(ngModel)]="nl.fname"
										#nlfname="ngModel"
										placeholder="{{'labels.fname' | translate}}"
										[ngClass]="{'is-invalid': nlForm.submitted && nlfname.invalid}"
										required>
								</div>
								<div class="col md-6">
									<input
										type="text"
										class="form-control"
										name="COGNOME"
										[(ngModel)]="nl.lname"
										#nllname="ngModel"
										placeholder="{{'labels.lname' | translate}}"
										[ngClass]="{'is-invalid': nlForm.submitted && nllname.invalid}"
										required>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-md-12">
									<input
										type="text"
										class="form-control"
										name="EMAIL"
										[(ngModel)]="nl.email"
										#nlemail="ngModel"
										email
										placeholder="{{'labels.email' | translate}}"
										[ngClass]="{'is-invalid': nlForm.submitted && nlemail.invalid}"
										required>
								</div>
							</div>
							<br>

							<div class="row">
								<div class="col-md-12">
									<button
										type="submit"
										class="btn btn-solid">
										{{'subscribe' | translate}}
									</button>
								</div>

							</div>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img
								width="200px"
								[src]="themeLogo"
								alt="logo">
						</div>
						<p>{{'footer-left' | translate}}</p>
						<p *ngIf="minCart!=0">
							{{('footer-minimumcart' | translate) + (minCart |currency:'EUR') }}
						</p>
						<p *ngIf="freeshipment!=-1">
							{{('footer-freeshipmentabove' | translate) + (freeshipment |currency:'EUR')}}
						</p>
						<div class="footer-social">
							<ul>
								<li>
									<a href="javascript:void(0)">
										<i
											class="fa fa-facebook"
											aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)">
										<i
											class="fa fa-google-plus"
											aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)">
										<i
											class="fa fa-twitter"
											aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)">
										<i
											class="fa fa-instagram"
											aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="javascript:void(0)">
										<i
											class="fa fa-rss"
											aria-hidden="true"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>{{'categories' | translate}}</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li>
									<a href="javascript:void(0)">Womens Fashion</a>
								</li>
								<li>
									<a href="javascript:void(0)">Mens Fashion</a>
								</li>
								<li>
									<a href="javascript:void(0)">Kids Fashion</a>
								</li>
								<li>
									<a href="javascript:void(0)">Featured</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>
								<h4>{{'footer-elem' | translate}}</h4>
							</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li>
									<a href="/pages/shipments-and-returns">Spedizioni e Resi</a>
								</li>
								<li>
									<a href="/pages/termini-e-condizioni">Termini e condizioni</a>
								</li>
								<li>
									<a href="/pages/privacy">Privacy Policy</a>
								</li>
								<li>
									<a href="javascript:void(0)">gallary</a>
								</li>
								<li>
									<a href="javascript:void(0)">affiliates</a>
								</li>
								<li>
									<a href="javascript:void(0)">contacts</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>
								<h4>{{'footer-store-info' | translate}}</h4>
							</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li>
									<i class="fa fa-map-marker"></i>
									YFCommerce Demo Store, Pistoia
								</li>
								<li>
									<i class="fa fa-phone"></i>
									Call Us: 123-456-7898
								</li>
								<li>
									<i class="fa fa-envelope-o"></i>
									Email Us:
									<a>support@elbuild.it</a>
								</li>
								<li>
									<i class="fa fa-fax"></i>
									Fax: 123456
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-7 col-sm-12">
					<div class="footer-end">
						<div class="copyright text-center">
							Copyright  ©  {{today | date: 'yyyy'}}
							<a
								id="pixinventLink"
								target="blank"
								href="https://www.elbuild.it/">
								https://www.elbuild.it/
							</a>
							, All rights reserved.
							<div class="pull-right">
								<span class="version">
									UI v. {{version.version}} {{envprod}} ({{version.hash}} {{version.date | date:'dd/MM/yy'}}
									<span *ngIf="version.tag">tag {{version.tag}}</span>
									)
								</span>

							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-md-5 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a>
									<img
										src="assets/images/icon/visa.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/mastercard.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/paypal.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/american-express.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/discover.png"
										alt>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<div
	class="cookie-banner-container"
	*ngIf="showcookie">
	<div class="cookie-banner">
		<div class="container">
			<div class="row mt-5 mb-5">
				<div class="col-md-9 mt-2">
					<p>{{'cookie-text' | translate}}</p>
				</div>
				<div class="col-md-3">
					<button
						(click)="hidecookie()"
						class="btn btn-solid float-right">
						{{'accept' | translate}}
					</button>
				</div>
			</div>
		</div>

	</div>
</div>
<!-- footer section end -->
