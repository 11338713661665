<ng-container *ngIf="isbrowser">
	<app-header-one *ngIf="env.theme !== 'flower'"></app-header-one>
	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>
	<app-breadcrumb
		[title]="'Reset Password'"
		[breadcrumb]="'Reset Password'"></app-breadcrumb>
	<!-- section start -->
	<section class="pwd-page">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h2>
						{{'reset-password-page.h2' | translate}}
					</h2>
					{{message}}
					<div *ngIf="done">
						Password aggiornata correttamente.
						<a [routerLink]="['/user/signin']">Esegui il login</a>
					</div>
					<div
						class="base-row row mt-5"
						*ngIf="form && !done">
						<div class="col-12">Scegli una nuova password</div>
						<div class="col-12 text-center  mt-5">
							<form
								#loginForm="ngForm"
								(ngSubmit)="loginForm.valid && submit()">
								<div class="row">
									<div
										class="form-group has-float-label col-md-6 text-left"
										[ngClass]="{'is-invalid': loginForm.submitted && passwordf.invalid}">
										<span
											class="font-weight-bold mb-2"
											for="password">
											Password *
										</span>

										<input
											type="password"
											class="form-control"
											placeholder="Password"
											name="passwordfield"
											[(ngModel)]="password"
											required
											#passwordf="ngModel">
										<div
											class="invalid"
											*ngIf="loginForm.submitted && passwordf.invalid">
											Inserisci una password valida
										</div>

									</div>

									<div
										class="form-group has-float-label col-md-6 text-left"
										[ngClass]="{'is-invalid': loginForm.submitted && rpasswordf.invalid}">
										<span
											class="font-weight-bold mb-2"
											for="passwordconfirm">
											Ripeti Password *
										</span>
										<input
											ngValidateEqual="passwordfield"
											type="password"
											class="form-control"
											placeholder="Ripeti Password"
											name="passwordconfirm"
											[(ngModel)]="rpassword"
											required
											#rpasswordf="ngModel">

										<div
											class="invalid"
											*ngIf="loginForm.submitted && rpasswordf.invalid">
											Le due password non coincidono
										</div>
									</div>

									<div class="col-md-12  text-right">
										<button
											class="btn btn-solid float-right"
											type="submit">
											Aggiorna password
										</button>
									</div>
								</div>
							</form>
						</div>

					</div>

				</div>
			</div>
		</div>
	</section>
	<!-- Section ends -->
</ng-container>
