<ng-container *ngIf="isbrowser">

	<app-header-one
		[sticky]="true"
		*ngIf="(env.theme == 'fashion' || env.theme == 'tools') &&  url != '/pages/comingsoon' && !landing"></app-header-one>

	<app-header-three *ngIf="env.theme == 'flower' && !landing"></app-header-three>
	<app-header-landing
		*ngIf="landing"
		[sticky]="true"></app-header-landing>
	<router-outlet></router-outlet>
	<app-footer-one *ngIf="url != '/pages/comingsoon'"></app-footer-one>
</ng-container>
