<ng-container *ngIf="isbrowser">
	<app-header-one *ngIf="env.theme !== 'flower'"></app-header-one>
	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>
	<app-breadcrumb
		[title]="'Login'"
		[breadcrumb]="'Login'"></app-breadcrumb>
	<!-- section start -->
	<section class="login-page section-b-space">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<h3>{{'login-page.title' | translate}}</h3>
					<div class="theme-card">
						<form
							class="theme-form"
							(ngSubmit)="login()">
							<div class="form-group">
								<label for="email">{{'labels.email' | translate}}</label>
								<input
									name="username"
									type="text"
									class="form-control"
									id="email"
									placeholder="{{'labels.email' | translate}}"
									[(ngModel)]="logindata.username"
									required>
							</div>
							<div class="form-group">
								<label for="review">{{'labels.password' | translate}}</label>
								<input
									name="password"
									[(ngModel)]="logindata.md5password"
									type="password"
									class="form-control"
									id="review"
									placeholder="{{'labels.password' | translate}}"
									required>
							</div>

							<button
								type="submit"
								class="btn btn-solid float-right">
								{{'buttons.login' | translate}}
							</button>
						</form>

						<p class="mb-2 text-muted">
							Password dimenticata?
							<a
								[routerLink]="['/user/forgot-password']"
								class="f-w-400">
								Reimposta
							</a>
						</p>

					</div>
				</div>
				<div
					class="col-lg-6 right-login"
					id="new-customer">
					<h3>
						{{'login-page.new-customer.title' | translate}}
					</h3>
					<div class="theme-card authentication-right">
						<h6
							translate
							class="title-font"
							innerHTML="{{'login-page.new-customer.h6' | translate}}">

						</h6>
						<p innerHTML="{{'login-page.new-customer.p' | translate}}"></p>
						<a
							[routerLink]="['/user/register']"
							translate
							class="btn btn-solid">
							{{'buttons.signin' | translate}}
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Section ends -->

</ng-container>
