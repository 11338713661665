<div class="img-wrapper">
	<div
		class="ribbon"
		*ngIf="product.ispromo">
		<span>new</span>
	</div>
	<div class="front">
		<a
			*ngIf="product"
			[routerLink]="['/shop/product/', product?.url]">
			<img
				(ready)="ready()"
				asyncimg
				[placeholder]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
				[opaque]="product?.cover"
				[alt]
				size="md"
				class="img-fluid">
		</a>
	</div>
	<div class="cart-info cart-wrap">
		<a
			title="{{(isInWishlist ? 'Add to wishlist' : 'Remove from wishlist') | translate}}"
			(click)="isInWishlist ? removeFromWishlist(product) : addToWishlist(product)">
			<i
				*ngIf="!isInWishlist"
				class="fa fa-heart-o"></i>
			<i
				*ngIf="isInWishlist"
				class="fa fa-heart"></i>

		</a>

		<button
			title="Add to cart"
			(click)="CartModal.openModal(product)"
			*ngIf="cartModal">
			<i class="ti-shopping-cart"></i>
			Add to cart
		</button>
		<button
			title="Add to cart"
			(click)="addToCart(product)"
			*ngIf="!cartModal">
			<i class="ti-shopping-cart"></i>
			Add to cart
		</button>
		<a
			*ngIf="false"
			href="javascript:void(0)"
			(click)="addToCompare(product)"
			title="Compare">
			<i
				class="fa fa-refresh"
				aria-hidden="true"></i>
		</a>
		<a
			*ngIf="false"
			href="javascript:void(0)"
			class="mobile-quick-view"
			(click)="QuickView.openModal()"
			title="Quick View">
			<i
				class="ti-search"
				aria-hidden="true"></i>
		</a>
	</div>
	<div class="quick-view-part">
		<a
			href="javascript:void(0)"
			(click)="QuickView.openModal()"
			title="Quick View">
			<i
				class="ti-search"
				aria-hidden="true"></i>
		</a>
	</div>
</div>
<div class="product-info">

	<a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
		<h6>{{ product?.title | titlecase }}</h6>
	</a>
	<h4>
		{{ product?.price  | currency:currency?.currency:'symbol' }}
		<del *ngIf="product?.discount">
			<span class="money">
				{{ product?.baseprice | currency:currency?.currency:'symbol' }}
			</span>
		</del>
	</h4>
</div>

<app-quick-view
	#quickView
	[product]="product"
	[currency]="currency"></app-quick-view>
<app-cart-modal
	#cartModal
	[product]="product"
	[currency]="currency"
	*ngIf="cartModal"></app-cart-modal>
