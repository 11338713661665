import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from 'elbuild-ui-lib-core';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

	@Input() title: string;
	@Input() breadcrumb: string;
	@Input() breadCrumb: Breadcrumb;

	constructor() {
	}

	ngOnInit(): void { }

}
