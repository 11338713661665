import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CatalogService } from 'yf-commerce-ui-lib';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { ShopProductService } from 'yf-commerce-ui-lib';
import { Router } from '@angular/router';
import { ShopCartService } from 'yf-commerce-ui-lib';
import { Product } from 'yf-commerce-model-ui-lib';

@Component({
	selector: 'app-product-box-base',
	templateUrl: './product-box-base.component.html',
	styleUrls: ['./product-box-base.component.scss']
})
export class ProductBoxBaseComponent implements OnInit {

	private _product: Product;
	@Input() currency: any = this.catalogService.Currency; // Default Currency
	@Input() thumbnail: boolean = false; // Default False
	@Input() onHowerChangeImage: boolean = false; // Default False
	@Input() cartModal: boolean = false; // Default False
	@Input() loader: boolean = true; // non usato

	@ViewChild('quickView') QuickView: QuickViewComponent;
	@ViewChild('cartModal') CartModal: CartModalComponent;


	loading: boolean;

	today = (new Date()).getTime();
	isInWishlist: boolean;


	@Input()
	set product(p: Product) {
		if (p) {
			this._product = p;
			this.isInWishlist = this.productService.isInWishlist(p.id);
			if (this._product.back) {
				this.onHowerChangeImage = true;
			}
			if (!this._product.cover) {
				setTimeout(() => { this.loading = false; }, 2000); // Skeleton Loader
			}
		}
	}

	get product(): Product {
		return this._product;
	}

	public ImageSrc: string;

	constructor(
		private catalogService: CatalogService,
		private productService: ShopProductService,
		private cartService: ShopCartService,
		private router: Router) {
		this.loading = true;
	}

	ngOnInit(): void {

	}

	ready() {
		setTimeout(() => { this.loading = false; }, 2000); // Skeleton Loader
	}
	// Get Product Color
	Color(variants) {
		const uniqColor = [];
		/*for (let i = 0; i < Object.keys(variants).length; i++) {
			if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
				uniqColor.push(variants[i].color);
			}
		}*/
		return uniqColor;
	}

	// Change Variants
	ChangeVariants(color, product) {
		/*product.variants.map((item) => {
			if (item.color === color) {
				product.images.map((img) => {
					if (img.image_id === item.image_id) {
						this.ImageSrc = img.src;
					}
				});
			}
		});*/
	}

	// Change Variants Image
	ChangeVariantsImage(src) {
		this.ImageSrc = src;
	}

	addToCart(product: Product): void {
		if (product.configurable) {
			this.router.navigate(['/shop/product/', product?.url]);
		} else {
			this.cartService.add(undefined, product, 1);

		}

	}

	addToWishlist(product: any) {
		this.productService.addToWishlist(product);
		this.isInWishlist = true;
	}

	removeFromWishlist(product: any) {
		this.productService.removeWishlistItem(product);
		this.isInWishlist = false;
	}

	addToCompare(product: any) {
		this.productService.addToCompare(product);
	}


}
