import { Component, OnInit } from '@angular/core';
import { CatalogService } from 'yf-commerce-ui-lib';
import { ShopProductService } from 'yf-commerce-ui-lib';
import { ProductBoxBaseComponent } from '../product-box-base/product-box-base.component';
import { Router } from '@angular/router';
import { ShopCartService } from 'yf-commerce-ui-lib';

@Component({
	selector: 'app-product-box-one',
	templateUrl: './product-box-one.component.html',
	styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent extends ProductBoxBaseComponent implements OnInit {

	constructor(catalogService: CatalogService,
		productService: ShopProductService,
		cartService: ShopCartService,
		router: Router) {
		super(catalogService, productService, cartService, router);
	}

	ngOnInit(): void {

	}



}
