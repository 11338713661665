<ng-container *ngIf="isbrowser">
	<app-header-one *ngIf="env.theme !== 'flower'"></app-header-one>
	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>
	<app-breadcrumb
		[title]="'forget-password-page.h2' | translate"
		[breadcrumb]="'Forget Password'"></app-breadcrumb>
	<!-- section start -->
	<section class="pwd-page">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 offset-lg-3">
					<h2>
						{{'forget-password-page.h2' | translate}}
					</h2>
					<form
						*ngIf="!done"
						class="theme-form"
						#loginForm="ngForm"
						(ngSubmit)="loginForm.valid && reset()">
						<div class="form-row">
							<div class="col-md-12">
								<input
									name="email"
									required
									[(ngModel)]="email"
									type="text"
									class="form-control"
									id="email"
									placeholder="{{'enter-email' | translate}}">
							</div>
							<button
								[disabled]="!loginForm.valid"
								type="submit"
								class="btn btn-solid">
								{{'buttons.submit' | translate}}
							</button>
						</div>
					</form>
					<div *ngIf="done">
						Se questa mail è registrata sul sito, riceverai a breve un link per remimpostare la password.
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Section ends -->
</ng-container>
