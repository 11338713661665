import { ErrorComponent } from './error/error.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { AuthenticationGuardService } from './frontend-auth/auth-guard.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	{
		path: 'landing',
		loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
	},
	{
		path: 'user',
		loadChildren: () => import('./frontend-auth/frontend-auth.module').then(m => m.FrontendAuthModule)
	},
	{
		path: 'print-wishlist',
		loadChildren: () => import('./print-wishlist/print-wishlist.module').then(m => m.PrintWishlistModule)
	},
	{
		path: 'sso',
		loadChildren: () => import('./sso/sso.module').then(m => m.SSOModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canActivate: [AuthenticationGuardService],
		canActivateChild: [AuthenticationGuardService]
	},
	{
		path: 'shop',
		component: ShopComponent,
		loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
		canActivate: [AuthenticationGuardService],
		canActivateChild: [AuthenticationGuardService]
	},
	{
		path: 'pages',
		component: PagesComponent,
		loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: 'elements',
		component: ElementsComponent,
		loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule),
		canActivate: [AuthenticationGuardService],
		canActivateChild: [AuthenticationGuardService]
	},
	{
		path: '404',
		component: ErrorComponent,
		data: {
			status: 404
		}
	},
	{
		path: '**', // Navigate to Home Page if not found any page
		redirectTo: '404',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabled',
		useHash: false,
		anchorScrolling: 'enabled',
		scrollPositionRestoration: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
