import { Component, OnInit, Input } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

	@Input()
	landing: boolean;

	public menuItems: Menu[];


	constructor(private router: Router, public navServices: NavService) {

	}

	ngOnInit(): void {
		console.log('menu init', this.landing);
		this.navServices.loadMenu(this.landing);
		this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
		this.router.events.subscribe((event) => {
			this.navServices.mainMenuToggle = false;
		});
	}

	mainMenuToggle(): void {
		this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
	}

	// Click Toggle menu (Mobile)
	toggletNavActive(item) {
		item.active = !item.active;
	}

	navigateTo(url: string) {
		console.log('navigateTo', url);
		this.router.navigateByUrl(url);
	}
}
