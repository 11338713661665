<div>
	<div class="img-wrapper">
		<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '380px' }"></ngx-skeleton-loader>
	</div>
	<div class="product-detail">
		<h4>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '15px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
		</h4>
		<h5>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}"></ngx-skeleton-loader>
		</h5>
		<h6>
			<ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '70px', 'border-radius': '15px',  height: '14px'}"></ngx-skeleton-loader>
		</h6>
	</div>
</div>
