import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';
import { SharedDataService, AuthService } from 'elbuild-ui-lib-core';
import { map } from 'rxjs/operators';
import { Customer } from 'yf-commerce-model-ui-lib';


@Injectable()
export class AuthenticationGuardService implements CanActivate, CanActivateChild {

	loggedUser: Customer;

	constructor(private sharedDataService: SharedDataService,
		private router: Router,
		private authService: AuthService) {
		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser as unknown as Customer; });
	}


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (this.loggedUser) {
			if (this.checkAclFeatures(route)) {
				return of(true);
			} else {
				return of(false);
			}
		} else {

			return this.authService.getLoggedUser().pipe(map((user) => {

				if (this.checkAclFeatures(route)) {
					return true;
				} else {
					const redirect = environment.b2b ? '/landing' : '/user/signin';
					this.router.navigate([redirect]);
					return false;
				}
			}, error => { console.log('auth guard service error', error); }));

		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (this.checkAclFeatures(route)) {
			return of(true);
		} else {
			return of(false);
		}
	}

	checkAclFeatures(route: ActivatedRouteSnapshot) {

		const roles = route.data.roles;

		if (!environment.b2b) {
			if (!roles || !roles.length) {
				return true;
			} else {
				return this.loggedUser && roles.find(i => i === 'logged'); // TODO poi ci saranno i vendor
			}
		}
		if (this.loggedUser) {
			return true;
		}

		return false;
	}
}
