import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-shop',
	templateUrl: './shop.component.html',
	styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy {
	env = environment;
	isbrowser: boolean;
	constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

	ngOnInit(): void {
		// Change color for this layout
		if (isPlatformBrowser(this.platformId)) {
			document.documentElement.style.setProperty('--theme-deafult', environment.themecolor);
		}
		this.isbrowser = isPlatformBrowser(this.platformId);
	}
	ngOnDestroy(): void {
		// Remove Color
		if (isPlatformBrowser(this.platformId)) {
			document.documentElement.style.removeProperty('--theme-deafult');
		}
	}


}
