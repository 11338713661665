import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Login, AuthService, SharedDataService, MessageService, AuthConfig, Staff, User, CrudService } from 'elbuild-ui-lib-core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5/dist/md5';
import { Customer } from 'yf-commerce-model-ui-lib';
import { plainToClass } from 'class-transformer';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


	confirmpassword: string;
	loggedUser: Customer;
	updated: boolean = false;
	isbrowser: boolean;

	get password(): string {
		return this.loggedUser?.plainpassword;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private sharedDataService: SharedDataService,
		private translateService: TranslateService,
		private router: Router,
		private messageService: MessageService,
		private customerService: CrudService<Customer>) {
		this.sharedDataService.currentLoggedUser.subscribe(data => {
			this.loggedUser = plainToClass(Customer, data);

		});

		this.isbrowser = isPlatformBrowser(this.platformId);
	}

	ngOnInit() {
	}

	changepwd() {
		this.customerService.saveEntity(Customer, 'customer', this.loggedUser).subscribe((data: Customer) => {
			this.loggedUser = data;
			this.confirmpassword = undefined;
			this.updated = true;
		});
	}


}
