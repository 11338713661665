<!-- footer section -->
<footer [ngClass]="class">
	<div
		class="dark-layout"
		*ngIf="mainFooter">
		<div class="container">
			<section class="section-b-space border-b">
				<div class="row footer-theme2">
					<div class="col-lg-3">
						<div class="footer-title footer-mobile-title">
							<h4>about</h4>
						</div>
						<div class="footer-contant">
							<div
								class="footer-logo"
								id="footerlogo">
								<img
									[src]="themeLogo"
									alt="logo">
							</div>
							<p>{{'footer-left' | translate}},</p>
						</div>
					</div>
					<div class="col-lg-6 subscribe-wrapper">
						<div class="subscribe-block">
							<h2>newsletter</h2>
							<form>
								<div class="form-group">
									<input
										type="text"
										class="form-control"
										id="exampleFormControlInput3"
										placeholder="{{'enter-email' | translate}}">
									<button
										type="submit"
										class="btn btn-solid">
										{{'subscribe' | translate}}
									</button>
								</div>
							</form>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="footer-title">
							<h4>
								<h4>{{'footer-store-info' | translate}}</h4>
							</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-details">
								<li>
									YFCommerce Demo Store, Demo store
                                    India 345-659
								</li>
								<li>Call Us: 123-456-7898</li>
								<li>
									Email Us:
									<a href="javascript:void(0)">Support@Fiot.com</a>
								</li>
								<li>Fax: 123456</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div
		class="dark-layout"
		*ngIf="subFooter">
		<div class="container">
			<section class="small-section">
				<div class="row footer-theme2">
					<div class="col p-set">
						<div class="footer-link">
							<div class="sub-title">
								<div class="footer-title">
									<h4>{{'categories' | translate}}</h4>
								</div>
								<div class="footer-contant">
									<ul>
										<li>
											<a href="javascript:void(0)">Womens Fashion</a>
										</li>
										<li>
											<a href="javascript:void(0)">Mens Fashion</a>
										</li>
										<li>
											<a href="javascript:void(0)">Kids Fashion</a>
										</li>
										<li>
											<a href="javascript:void(0)">Featured</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="footer-link-b">
							<div class="sub-title">
								<div class="footer-title">
									<h4>
										<h4>{{'footer-elem' | translate}}</h4>
									</h4>
								</div>
								<div class="footer-contant">
									<ul>
										<li>
											<a href="javascript:void(0)">shipping & return</a>
										</li>
										<li>
											<a href="javascript:void(0)">secure shopping</a>
										</li>
										<li>
											<a href="javascript:void(0)">gallary</a>
										</li>
										<li>
											<a href="javascript:void(0)">affiliates</a>
										</li>
										<li>
											<a href="javascript:void(0)">contacts</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div class="sub-footer darker-subfooter">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<div class="footer-end">
							<div class="copyright text-center">
								Copyright  ©  {{today | date: 'yyyy'}}
								<a
									id="pixinventLink"
									target="blank"
									href="https://www.elbuild.it/">
									https://www.elbuild.it/
								</a>
								, All rights reserved.
								<div class="pull-right">
									<span class="version">
										UI v. {{version.version}} {{env}} ({{version.hash}} {{version.date | date:'dd/MM/yy'}}
										<span *ngIf="version.tag">tag {{version.tag}}</span>
										)
									</span>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-6 col-md-6 col-sm-12">
				<div class="payment-card-bottom">
					<ul>
						<li>
							<a href="javascript:void(0)">
								<img
									src="assets/images/icon/visa.png"
									alt>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<img
									src="assets/images/icon/mastercard.png"
									alt>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<img
									src="assets/images/icon/paypal.png"
									alt>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<img
									src="assets/images/icon/american-express.png"
									alt>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<img
									src="assets/images/icon/discover.png"
									alt>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</footer>
<!-- footer section end -->
