<!-- breadcrumb start -->
<div class="breadcrumb-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="page-title">
					<h2>{{ title }}</h2>
				</div>
			</div>
			<div class="col-sm-6">
				<nav
					aria-label="breadcrumb"
					class="theme-breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a [routerLink]="'/'">Home</a>
						</li>
						<li
							class="breadcrumb-item active"
							aria-current="page"
							*ngIf="breadcrumb && !breadCrumb">
							{{ breadcrumb }}
						</li>
						<ng-template [ngIf]="breadCrumb">
							<li
								class="breadcrumb-item"
								*ngFor="let b of breadCrumb.items; let last = last">
								<a
									[routerLink]="b.url"
									class="breadcrumb-item"
									[ngClass]="{'active': last}">
									{{b.title}}
								</a>
							</li>
						</ng-template>

					</ol>
				</nav>
			</div>
		</div>
	</div>
</div>
<!-- breadcrumb End -->
