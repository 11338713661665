import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private transferState: TransferState,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


		if (isPlatformBrowser(this.platformId)) {
			//console.log('BROWSER REQUEST ' + req.url);

			if (req.url.startsWith(environment.apiprefix) && !req.url.startsWith(environment.relativeapiurl)) {
				req = req.clone({ url: req.url.replace(environment.apiprefix, environment.relativeapiurl) });

			}


			if (req.method !== 'GET') {
				return next.handle(req);
			}

			/* First, we are checking if the request method is GET, if not we pass
			the request to the next interceptor or to http client.
	Then we are trying to get saved data from transferState.
	If there is a data, we create a new HttpResponse object with our data and return it,
	so no other interceptor will intercept this request.
	If there is nothing in transferState for given key (url) we are passing
	request to next interceptor or http client to actually make the request. */


			const storedResponse: string = this.transferState.get(makeStateKey(req.url), null);

			if (storedResponse) {
				const response = new HttpResponse({ body: storedResponse, status: 200 });
				return of(response);
			}
		}
		return next.handle(req);
	}
}
