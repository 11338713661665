<owl-carousel-o
	[options]="LogoSliderConfig"
	class="no-arrow">

	<ng-container *ngFor="let logo of logos">
		<ng-template carouselSlide>
			<div>
				<div class="logo-block">
					<a [href]="rootcat ? ('/shop/collection/'+rootcat+'?prodattr=' + logo.attributeid+':'+logo.attributevalid) : ''">
						<span class="logo-title">{{logo.brand }}</span>
						<img
							width="200"
							height="200"
							asyncimg
							[opaque]="logo.logo"
							alt="logo"
							class="w-auto">
					</a>
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>
