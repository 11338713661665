<div class="img-wrapper">
	<div class="lable-block">
		<span
			class="lable3"
			*ngIf="product.ispromo">
			new
		</span>
		<span
			class="lable4"
			*ngIf="product.ispromo">
			<span
				*ngIf="product?.promopercentage"
				class="promo-percentage">
				- {{product?.promopercentage}}%
			</span>
		</span>
	</div>
	<div class="front">
		<a
			*ngIf="product"
			[routerLink]="['/shop/product/', product?.url]">
			<img
				asyncimg
				[placeholder]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
				[opaque]="product?.cover"
				[alt]
				size="md"
				class="img-fluid">

		</a>
	</div>
	<div class="cart-box">
		<button
			title="Add to cart"
			(click)="false && CartModal.openModal(product)"
			*ngIf="cartModal">
			<i class="ti-shopping-cart"></i>
		</button>
		<button
			title="Add to cart"
			(click)="false && addToCart(product)"
			*ngIf="!cartModal">
			<i class="ti-shopping-cart"></i>
		</button>
		<a
			href="javascript:void(0)"
			title="Add to Wishlist"
			(click)="addToWishlist(product)">
			<i
				class="ti-heart"
				aria-hidden="true"></i>
		</a>
		<a
			*ngIf="false"
			href="javascript:void(0)"
			title="Quick View"
			(click)="QuickView.openModal()">
			<i
				class="ti-search"
				aria-hidden="true"></i>
		</a>

	</div>
</div>
<div class="product-detail text-center">
	<div
		*ngIf="false"
		class="rating">

	</div>
	<a [routerLink]="['/shop/product/left/sidebar/', product.slug]">
		<h6>{{ product.title | titlecase }}</h6>
	</a>
	<h4>
		{{ product?.price  | currency:currency?.currency:'symbol' }}
		<del *ngIf="product?.discount">
			<span class="money">
				{{ product?.baseprice | currency:currency?.currency:'symbol' }}
			</span>
		</del>
	</h4>
	<ul
		class="color-variant"
		*ngIf="Color(product?.variants).length">
		<li
			[class]="color"
			*ngFor="let color of Color(product?.variants)"
			[ngStyle]="{'background-color': color}"
			(click)="ChangeVariants(color, product)">
		</li>
	</ul>
</div>

<app-quick-view
	#quickView
	[product]="product"
	[currency]="currency"></app-quick-view>
<app-cart-modal
	#cartModal
	[product]="product"
	[currency]="currency"
	*ngIf="cartModal"></app-cart-modal>
