import { ShopCartService, WishlistService } from 'yf-commerce-ui-lib';
import { Customer } from 'yf-commerce-model-ui-lib';
import { SharedDataService, AuthService, CrudService } from 'elbuild-ui-lib-core';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-header-base',
	templateUrl: './header-base.component.html',
	styleUrls: ['./header-base.component.scss']
})
export class HeaderBaseComponent implements OnInit {

	@Input() class: string;
	@Input() themeLogo: string = 'assets/images/icon/' + environment.logo; // Default Logo
	@Input() topbar: boolean = true; // Default True
	@Input() sticky: boolean = false; // Default false

	public stick: boolean = false;
	loggedUser: Customer;
	ismobile: boolean;

	constructor(
		protected sharedDataService: SharedDataService,
		protected authService: AuthService,
		protected router: Router,
		protected cartService: ShopCartService,
		protected customerService: CrudService<Customer>,
		protected wishlistService: WishlistService,
		protected deviceService: DeviceDetectorService) {
		this.sharedDataService.currentLoggedUser.subscribe(data => {
			this.loggedUser = plainToClass(Customer, data);

		});

		this.ismobile = this.deviceService.isMobile() || this.deviceService.isTablet();

	}

	ngOnInit(): void {


	}

	logout(): void {
		this.authService.logout();
		this.cartService.clearData();
		this.wishlistService.clearData();
		this.cartService.initShop(environment.storecode);
		this.wishlistService.initShop(environment.storecode);
		if (environment.b2b) {
			this.router.navigate(['/landing']);
		} else {
			this.router.navigate(['/home']);
		}

	}

	// @HostListener Decorator
	@HostListener('window:scroll', [])
	onWindowScroll() {
		const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		if (number >= 300 && window.innerWidth > 400) {
			this.stick = true;
		} else {
			this.stick = false;
		}
	}

}
