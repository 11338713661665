<ng-container *ngIf="isbrowser">
	<app-header-one
		[sticky]="true"
		*ngIf="env.theme == 'fashion'"></app-header-one>

	<app-header-one *ngIf="env.theme == 'tools'"></app-header-one>

	<app-header-three *ngIf="env.theme == 'flower'"></app-header-three>
	<router-outlet></router-outlet>
	<app-footer-one></app-footer-one>
</ng-container>
