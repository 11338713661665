import 'reflect-metadata';

import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';

import { YfCommerceUiLibModule } from 'yf-commerce-ui-lib';
import { ElbuildUiLibModule, AuthConfig, BaseAuthModule, ElbuildCoreServicesModule } from 'elbuild-ui-lib-core';
import { ElbuildCoreComponentsModule, ElbuildUiComponentslibModule } from 'elbuild-ui-componentslib';
import { ElbuildUiPaymentslibModule } from 'elbuild-ui-paymentslib';

// import 'hammerjs';
import 'mousetrap';
import { FrontendAuthModule } from './frontend-auth/frontend-auth.module';

import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { translateBrowserLoaderFactory } from './ssr/translate-browser.loader';
import { TransferState } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserStateInterceptor } from './ssr/browserstate.interceptor';
import { ErrorComponent } from './error/error.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	// return new TranslateHttpLoader(http, './assets/i18n/', '.json');
	return new TranslateHttpLoader(http);
}

const authConfig: AuthConfig = {
	cookieprefix: 'YF-customer-' + environment.storecode,
	loginbase: 'access/customer',
	loginpage: 'signin',
	usertable: 'customer',
	domain: environment.shopURL,
	proxyfolder: '/ws/',
	i18napi: true
};

@NgModule({
	declarations: [
		AppComponent,
		ShopComponent,
		PagesComponent,
		ElementsComponent,
		ErrorComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		HttpClientModule,
		NgbModule,
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			progressBar: false,
			enableHtml: true,
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateBrowserLoaderFactory,
				deps: [HttpClient, TransferState]
			}
		}),
		SharedModule,
		AppRoutingModule,
		YfCommerceUiLibModule.forRoot(),
		ElbuildUiLibModule.forRoot(authConfig),
		ElbuildUiComponentslibModule.forRoot(),
		ElbuildUiPaymentslibModule.forRoot(),
		ElbuildCoreComponentsModule,
		FrontendAuthModule,
		BaseAuthModule,
		ElbuildCoreServicesModule,
		NgxStripeModule.forRoot(),
		BrowserTransferStateModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BrowserStateInterceptor,
			multi: true,
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
