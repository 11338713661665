import { SharedDataService } from 'elbuild-ui-lib-core';
import { TagService } from './shared/services/tag.service';
import { NavService } from './shared/services/nav.service';
import { Component, PLATFORM_ID, Inject, AfterViewInit, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { combineLatest } from 'rxjs';
import { ShopCartService, WishlistService, FacebookService, ShopService } from 'yf-commerce-ui-lib';

declare let ga: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {


	// For Progressbar
	loaders = this.loader.progress$.pipe(
		delay(1000),
		withLatestFrom(this.loader.progress$),
		map(v => v[1]),
	);
	isbrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private loader: LoadingBarService, translate: TranslateService,
		private cartService: ShopCartService,
		private wishlistService: WishlistService,
		public router: Router,
		private route: ActivatedRoute,
		private navServices: NavService,
		private facebookService: FacebookService,
		private tagService: TagService,
		private shopService: ShopService,
		private shareddataservice: SharedDataService) {
		if (isPlatformBrowser(this.platformId)) {
			translate.setDefaultLang(environment.defaultlang);
			translate.addLangs(['en', 'it']);

			this.cartService.initShop(environment.storecode);
			this.cartService.loadCart();

			this.facebookService.init(environment.fbid);

			this.wishlistService.initShop(environment.storecode);
			this.wishlistService.loadWishlist();

			this.isbrowser = true;
		}

		this.shopService.getShopConf().subscribe(res => {
			if (res && res.minimumcart) {
				this.shareddataservice.addCommonData('mincart', res.minimumcart / 100);
			}
			if (res && res.freeshipment) {
				this.shareddataservice.addCommonData('freeshipment', res.freeshipment / 100);
			}
			this.shareddataservice.addCommonData('mktenabled', res.mktenabled / 100);
			this.shareddataservice.addCommonData('newsletterenabled', res.newsletterenabled / 100);
			this.shareddataservice.addCommonData('brandattrid', res.brandattrid);
		})



	}

	ngOnInit(): void {
		this.tagService.init();

	}




	ngOnDestroy() {

	}


	ngAfterViewInit(): void {

		this.router.events.pipe(
			filter(event => {
				const routerUrl = event['urlAfterRedirects'];
				if (routerUrl && typeof routerUrl === 'string') {
					if (isPlatformBrowser(this.platformId)) {
						ga('set', 'page', event['urlAfterRedirects']);
						ga('send', 'pageview');
					}
				}
				return event instanceof NavigationEnd;
			}))
			.pipe(map(() => {
				this.leftMenuClose();
				return this.route;
			})).pipe(
				map(route => {
					while (route['firstChild']) {
						route = route['firstChild'];
					}
					return route;
				})).pipe(
					filter(route => route['outlet'] === 'primary')).subscribe(route => {
						combineLatest([route['data'], route['url']]).subscribe(data => {
							this.tagService.setPageTags(data[0], this.router.url);
						});

					});


	}


	leftMenuClose(): void {
		this.navServices.leftMenuToggle = false;
	}


}
