<div class="icon-nav">
	<ul>
		<li
			*ngIf="!landing"
			class="onhover-div mobile-search search-widgets"
			id="search-widgets">
			<div *ngIf="!searching">
				<img
					(click)="opensearch()"
					src="assets/images/icon/search.png"
					class="img-fluid"
					alt>
				<i
					(click)="search()"
					class="ti-search"></i>
			</div>
			<div
				*ngIf="searching"
				class="search-block inline">
				<form
					(ngSubmit)="search()"
					class="form-header">
					<div class="form-group">
						<input
							name="search"
							#searchinput
							[(ngModel)]="searchterm"
							type="text"
							class="form-control"
							id="search"
							placeholder="{{'search-prod' | translate}}">
					</div>
					<button
						type="submit"
						class="btn btn-primary">
						<i class="fa fa-search"></i>
					</button>
				</form>
			</div>
		</li>
		<!-- <li class="onhover-div mobile-setting">
			<div>
				<img
					src="assets/images/icon/global.png"
					class="img-fluid"
					alt="settong">
				<i class="ti-settings"></i>
			</div>
			<div class="show-div setting">
				<h6 translate>{{'labels.language' | translate}}</h6>
				<ul>
					<li *ngFor="let language of languages">
						<a
							href="javascript:void(0)"
							(click)="changeLanguage(language.code)">
							<span [ngClass]="{'selected': language.code === selectedlang}">{{ language.name }}</span>
						</a>
					</li>
				</ul>
				 <h6>currency</h6>
				<ul class="list-inline">
					<li *ngFor="let currency of currencies">
						<a
							href="javascript:void(0)"
							(click)="changeCurrency(currency)">
							{{ currency.name }}
						</a>
					</li>
				</ul>
			</div>
		</li> -->



		<li class="onhover-div mobile-setting">
			<div>
				<a [routerLink]="['/']">



					<img
						src="assets/images/icon/global.png"
						class="img-fluid"
						alt="home">
					<i class="ti-home"></i>
				</a>
			</div>
			<div
				*ngIf="!ismobile"
				class="show-div setting">
				<h6 translate>{{'labels.language' | translate}}</h6>
				<ul>
					<li *ngFor="let language of languages">
						<a
							href="javascript:void(0)"
							(click)="changeLanguage(language.code)">
							<span [ngClass]="{'selected': language.code === selectedlang}">{{ language.name }}</span>
						</a>
					</li>
				</ul>
				<h6 *ngIf="false">currency</h6>
				<ul
					*ngIf="false"
					class="list-inline">
					<li *ngFor="let currency of currencies">
						<a
							href="javascript:void(0)"
							(click)="changeCurrency(currency)">
							{{ currency.name }}
						</a>
					</li>
				</ul>
			</div>



		</li>
		<li
			*ngIf="!landing && ismobile"
			class="onhover-div mobile-cart">
			<div>
				<a [routerLink]="['/shop/cart']">
					<img
						src="assets/images/icon/cart.png"
						class="img-fluid"
						alt>
					<i class="ti-shopping-cart"></i>
					<span class="cart_qty_cls">{{ cart?.items?.length || 0 }}</span>
				</a>



			</div>



		</li>
		<li
			*ngIf="!landing && !ismobile"
			class="onhover-div mobile-cart">
			<div>
				<a [routerLink]="['/shop/cart']">
					<img
						src="assets/images/icon/cart.png"
						class="img-fluid"
						alt>
					<i class="ti-shopping-cart"></i>
				</a>
			</div>
			<span class="cart_qty_cls">{{ cart?.items?.length || 0 }}</span>
			<ul
				class="show-div shopping-cart"
				*ngIf="!cart?.items?.length">
				<h5>{{'shopping.empty-cart' | translate}}</h5>
			</ul>
			<ul
				class="show-div shopping-cart"
				*ngIf="cart?.items?.length">
				<li *ngFor="let product of cart?.items">
					<div class="media">
						<a [routerLink]="['/shop/product/', product.url]">
							<img
								asyncimg
								placeholder="assets/images/product/placeholder.jpg"
								[opaque]="product.img"
								[size]="'xs'"
								class="mr-3"
								[alt]>
						</a>
						<div class="media-body">
							<a [routerLink]="['/shop/product/', product.url]">
								<h4>{{ product.name }}</h4>
							</a>
							<h4>
								<span>
									{{ product.qty }} x
                  {{ product.unitprice + product.unitvat | currency:productService?.Currency.currency:'symbol' }}
								</span>
							</h4>
						</div>
					</div>
					<div
						class="close-circle"
						(click)="removeItem(product)">
						<a href="javascript:void(0)">
							<i
								class="fa fa-times"
								aria-hidden="true"></i>
						</a>
					</div>
				</li>
				<li>
					<div class="total">
						<h5>
							{{'labels.subtotal' | translate}} :
							<span>
								{{ cart.total | currency:productService?.Currency.currency:'symbol' }}
							</span>
						</h5>
					</div>
				</li>
				<li>
					<div class="buttons">
						<a
							[routerLink]="['/shop/cart']"
							class="view-cart">
							{{'shopping.view-cart' | translate}}
						</a>
						<a
							*ngIf="loggedUser"
							[href]="checkoutURL"
							class="checkout">
							{{'shopping.checkout' | translate}}
						</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
